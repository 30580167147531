import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    openProjectCTAModal: false,
    openScreeningQuestionAdminModal: false,
    selectedExpertForScreening: {},
    ctaExpertQna: {},
    type: "create",
    answerAttachement: [],
    ctaProjectList: [],
    selectedCtaProject: {},
    selectedCtaProjectAdmin: {},
    selectedCtaProjectId: "",
    count: 0,
    industryOptions: [],
    subIndustryOptions: [],
    fileList: [],
    deletedfileList: [],
    tempIndustry: "",
    tempSubIndustry: "",
    open: false,
    opensub: false,
    key: "",
    overview: "",
    formvalues: {
        "title": "",
        "geography": [],
        "area_of_expertise": [],
        "client_type": [],
        "attachement": null //only to set for formfield
    },
    questions: [{ id: 0, label: "" }],
    questionAnswerInput: [{ question: "", answer: "", is_attachment: false }],
    isOpenDelete: false,
    isLoadingSubmit: false,
    isLoadingDelete: false,
    isLoadingAccept: false,
    isLoadingReject: false,
    isLoadingApply: false,
    page: 1,
    limit: 10

}

const projectCTASlice = createSlice({
    name: "projectCTA",
    initialState: initialState,
    reducers: {
        setCTAExpertQna: (state, { payload }) => {
            state.ctaExpertQna = payload
        },
        setOpenProjectCTAModal: (state, { payload }) => {
            state.openProjectCTAModal = payload
        },
        setSelectedExpertForScreening: (state, { payload }) => {
            state.selectedExpertForScreening = payload
        },
        setOpenScreeningQuestionAdminModal: (state, { payload }) => {
            state.openScreeningQuestionAdminModal = payload
        },
        setFileList: (state, { payload }) => {
            state.fileList = payload
        },
        setFormValues: (state, { payload }) => {
            state.formvalues = payload
        },
        setOverview: (state, { payload }) => {
            state.overview = payload
        },
        setOpen: (state, { payload }) => {
            state.open = payload
        },
        setKey: (state, { payload }) => {
            state.key = payload
        },
        setIsOpenDelete: (state, { payload }) => {
            state.isOpenDelete = payload
        },
        setOpenSub: (state, { payload }) => {
            state.opensub = payload
        },
        setdeletedFileList: (state, { payload }) => {
            state.deletedfileList = payload
        },
        setIndustryOptions: (state, { payload }) => {
            state.industryOptions = payload
        },
        setSubIndustryOptions: (state, { payload }) => {
            state.subIndustryOptions = payload
        },
        setType: (state, { payload }) => {
            state.type = payload
        },
        setQuestions: (state, { payload }) => {
            state.questions = payload
        },
        setAnswerAttachement: (state, { payload }) => {
            state.answerAttachement = payload
        },
        setTempIndustry: (state, { payload }) => {
            state.tempIndustry = payload
        },
        setTempSubIndustry: (state, { payload }) => {
            state.tempSubIndustry = payload
        },
        setIsLoadingSubmit: (state, { payload }) => {
            state.isLoadingSubmit = payload
        },
        setIsLoadingApply: (state, { payload }) => {
            state.isLoadingApply = payload
        },
        setIsLoadingDelete: (state, { payload }) => {
            state.isLoadingDelete = payload
        },
        setIsLoadingAccept: (state, { payload }) => {
            state.isLoadingAccept = payload
        },
        setIsLoadingReject: (state, { payload }) => {
            state.isLoadingReject = payload
        },
        setPage: (state, { payload }) => {
            state.page = payload
        },
        setLimit: (state, { payload }) => {
            state.limit = payload
        },
        setCTAProjectList: (state, { payload }) => {
            state.ctaProjectList = payload
        },
        setCountData: (state, { payload }) => {
            state.count = payload
        },
        setSelectedCTAProject: (state, { payload }) => {
            state.selectedCtaProject = payload
        },
        setSelectedCTAProjectAdmin: (state, { payload }) => {
            state.selectedCtaProjectAdmin = payload
        },
        setSelectedCTAProjectId: (state, { payload }) => {
            state.selectedCtaProjectId = payload
        },
        setQuestionAnswerInput: (state, { payload }) => {
            state.questionAnswerInput = payload
        },
        clearAll: (state) => {
            state.overview = ""
            state.formvalues = {
                "title": "",
                "geography": [],
                "area_of_expertise": [],
                "client_type": [],
                "attachement": null //only to set for formfield
            }
            state.selectedCtaProjectAdmin = {}
            state.questions = [{ id: 0, label: "" }]
            state.fileList = []
            state.deletedfileList = []
            state.tempIndustry = ""
            state.tempSubIndustry = ""
            state.open = false
            state.opensub = false
            state.selectedCtaProjectId = ""
            state.selectedExpertForScreening = {}
            state.ctaExpertQna = {}
            state.type = "create"
            state.answerAttachement = []
            state.ctaProjectList = []
            state.selectedCtaProject = {}
        }
    }

})
export const projectCTAReducer = projectCTASlice.reducer
export const { setOpenProjectCTAModal, setIndustryOptions, setOpenScreeningQuestionAdminModal, setSubIndustryOptions, setQuestions, setCTAExpertQna,
    setType, setFileList, setdeletedFileList, setTempIndustry, setTempSubIndustry, setOpen, setKey,
    setOpenSub, setSelectedCTAProjectAdmin, setIsLoadingDelete, setIsLoadingApply, setIsLoadingAccept, setIsLoadingReject, setFormValues, clearAll, setIsLoadingSubmit, setPage, setLimit, setCTAProjectList, setCountData, setSelectedCTAProject, setSelectedCTAProjectId, setIsOpenDelete, setOverview, setAnswerAttachement, setQuestionAnswerInput, setSelectedExpertForScreening } = projectCTASlice.actions

