import { useCallback, useMemo, useRef } from 'react'
import API_URL from '../../../../Constants/api-path';
import useSWR from 'swr';

import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Form, notification } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { clearAll, setCountData, setCTAExpertQna, setCTAProjectList, setFileList, setFormValues, setIsLoadingAccept, setIsLoadingApply, setIsLoadingDelete, setIsLoadingReject, setIsLoadingSubmit, setIsOpenDelete, setKey, setOpenProjectCTAModal, setOpenScreeningQuestionAdminModal, setOverview, setQuestions, setSelectedCTAProject, setType } from '../../../TeamProjects/features/projectCTA.slice';
import { useSearchParams } from 'react-router-dom';

const useCTAList = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const onlyOnce = useRef(false)
    const [searchParams] = useSearchParams()
    let project_id = searchParams.get("project_id") || null
    const userData = JSON.parse(localStorage.getItem("userData"));
    const userDataExpert = JSON.parse(localStorage.getItem("expertData"));
    const { formvalues, fileList, selectedCtaProjectId, selectedCtaProject, selectedCtaProjectAdmin, openScreeningQuestionAdminModal, questions, overview, ctaProjectList, questionAnswerInput, answerAttachement, selectedExpertForScreening, ctaExpertQna } = useSelector(
        (state) => state.projectCTA
    );

    const getCTAProjectList = useCallback(async () => {
        try {
            const res = await axios.get(`${API_URL.UPLOAD_CTA_PROJECT}?expert_id=${userDataExpert?.data?._id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userDataExpert?.token}`,
                },
            });
            return res

        } catch (error) {
            return error
        };
    }, [userDataExpert?.data?._id, userDataExpert?.token])

    const getCTAQuestionAnswer = useCallback(async (expert_id) => {
        try {
            const res = await axios.get(`${API_URL.ADD_QUESTION_ANSWER}/${expert_id}?project_id=${project_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            return res

        } catch (error) {
            return error
        };
    }, [project_id, userData?.token])

    const getCTAProjectById = useCallback(async () => {
        try {
            const res = await axios.get(`${API_URL.UPLOAD_CTA_PROJECT}/${selectedCtaProjectId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            return res

        } catch (error) {
            return error
        };
    }, [selectedCtaProjectId, userData?.token])


    const assignProjectDetailsToProject = useCallback(async () => {
        if (selectedCtaProjectAdmin?._id) {
            dispatch(setType("create"))
            let obj = {
                "title": selectedCtaProjectAdmin?.project_title,
                "geography": [...selectedCtaProjectAdmin?.country?.split(",")],
                "area_of_expertise": [],
                "client_type": [],
                "attachement": null,//only to set for formfield
            }
            dispatch(setOverview(selectedCtaProjectAdmin?.question))
            dispatch(setFormValues(obj))
            dispatch(setQuestions(selectedCtaProjectAdmin?.questions ? selectedCtaProjectAdmin?.questions?.map((que, index) => ({ id: index, label: que })) : [{ question: "", answer: "", is_attachment: false }]))
            let mappedObj = selectedCtaProjectAdmin?.attachments?.map((doc) => ({
                uid: doc?.s3key, // unique identifier for the file
                name: doc?.file_original_name, // name of the file
                status: "done", // status 'done' because it's already uploaded
                url: doc?.s3url, // use the link you already have for the file
            }))
            dispatch(setFileList(mappedObj))
            form.setFieldsValue({
                "title": selectedCtaProjectAdmin?.project_title,
                "geography": [...selectedCtaProjectAdmin?.country?.split(",")],
                "area_of_expertise": [],
                "client_type": [],
                "attachement": mappedObj,//only to set for formfield
                "overview": selectedCtaProjectAdmin?.question,
            })
        } else {
            dispatch(setFormValues(formvalues))
            form.setFieldsValue(formvalues);
        }
    }, [dispatch, form, formvalues, selectedCtaProjectAdmin?._id, selectedCtaProjectAdmin?.attachments, selectedCtaProjectAdmin?.country, selectedCtaProjectAdmin?.project_title, selectedCtaProjectAdmin?.question, selectedCtaProjectAdmin?.questions])

    const { isLoading: isLoadingCtaList } = useSWR(["get_cta_project_list", userDataExpert?.data?._id], () => userDataExpert?.data?._id ? getCTAProjectList() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            if (result?.data?.data) {
                dispatch(setCTAProjectList(result?.data?.data))
                dispatch(setCountData(result?.data?.countData))
            }
        },
        onError: (err) => {
            console.error("Industry ERROR", err);
        }
    })

    const { isLoading: isLoadingCTAProject } = useSWR(["get_cta_project_by_id", selectedCtaProjectId], () => !["", undefined, null]?.includes(selectedCtaProjectId) ? getCTAProjectById() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            if (result?.data?.data?._id) {
                dispatch(setType("update"))
                dispatch(setSelectedCTAProject(result?.data?.data));
                let questionObj = result?.data?.data?.questions?.map((question) => {
                    return {
                        question: question
                    };
                });
                let obj = {
                    "title": result?.data?.data?.project_name,
                    "geography": result?.data?.data?.geography,
                    "area_of_expertise": result?.data?.data?.area_of_expertise,
                    "client_type": result?.data?.data?.client_type,
                    "attachement": null,//only to set for formfield
                }
                dispatch(setOverview(result?.data?.data?.overview))
                dispatch(setFormValues(obj))
                dispatch(setQuestions(result?.data?.data?.questions?.map((que, index) => ({ id: index, label: que }))))
                let mappedObj = result?.data?.data?.documents?.map((doc) => ({
                    uid: doc?.s3key, // unique identifier for the file
                    name: doc?.file_original_name, // name of the file
                    status: "done", // status 'done' because it's already uploaded
                    url: doc?.s3url, // use the link you already have for the file
                }))
                dispatch(setFileList(mappedObj))
                form.setFieldsValue({
                    ...questionObj,
                    "title": result?.data?.data?.project_name,
                    "geography": result?.data?.data?.geography,
                    "area_of_expertise": result?.data?.data?.area_of_expertise,
                    "client_type": result?.data?.data?.client_type,
                    "attachement": mappedObj,//only to set for formfield
                    "overview": result?.data?.data?.overview,
                })

            } else {
                dispatch(setType("create"))
                assignProjectDetailsToProject()

            }
        },
        onError: (err) => {
            console.error("Industry ERROR", err);
        }
    })
    const { isLoading: isLoadingExpertQuestion } = useSWR(["get_question_answer", openScreeningQuestionAdminModal, selectedExpertForScreening], () => openScreeningQuestionAdminModal && selectedExpertForScreening?.expert_id ? getCTAQuestionAnswer(selectedExpertForScreening?.expert_id) : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (result) => {
            dispatch(setCTAExpertQna(result?.data?.data))
        },
        onError: (err) => {
            console.error("Industry ERROR", err);
        }
    })

    const refetchDocList = useCallback(async () => {
        let res = await getCTAProjectList()
        if (res?.data?.data) {
            dispatch(setCTAProjectList(res?.data?.data))
            dispatch(setCountData(res?.data?.countData))
        }
    }, [dispatch, getCTAProjectList])

    const uploadCTAProject = useCallback(async () => {
        dispatch(setIsLoadingSubmit(true))
        const formData = new FormData()
        formData.append("project_name", formvalues?.title)
        formData.append("client_type", formvalues?.client_type)
        formData.append("area_of_expertise", JSON.stringify(formvalues?.area_of_expertise))
        formData.append("geography", JSON.stringify(formvalues?.geography))
        formData.append("questions", JSON.stringify(questions?.map((obj) => obj?.label)))
        formData.append("overview", overview)
        formData.append("document", fileList?.[0]?.originFileObj)
        formData.append("admin_id", userData?.data?._id)
        formData.append("project_id", selectedCtaProjectId)
        try {
            let res = await axios.post(`${API_URL.UPLOAD_CTA_PROJECT}`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(setOpenProjectCTAModal(false));
                dispatch(clearAll());
                dispatch(setIsLoadingSubmit(false))
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Meeting Data Uploaded Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);

        }
    }, [dispatch, fileList, form, formvalues?.area_of_expertise, formvalues?.client_type, formvalues?.geography, formvalues?.title, overview, questions, selectedCtaProjectId, userData?.data?._id, userData?.token])

    const updateCTAProject = useCallback(async (id) => {
        dispatch(setIsLoadingSubmit(true))
        const formData = new FormData()
        formData.append("project_name", formvalues?.title)
        formData.append("client_type", formvalues?.client_type)
        formData.append("area_of_expertise", JSON.stringify(formvalues?.area_of_expertise))
        formData.append("geography", JSON.stringify(formvalues?.geography))
        formData.append("questions", JSON.stringify(questions?.map((obj) => obj?.label)))
        formData.append("overview", overview)
        formData.append("admin_id", userData?.data?._id)

        if (fileList?.[0]?.originFileObj) {
            formData.append("document", fileList?.[0]?.originFileObj)
        }
        try {
            let res = await axios.put(`${API_URL.UPLOAD_CTA_PROJECT}/${selectedCtaProjectId}`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(setOpenProjectCTAModal(false));
                dispatch(clearAll());
                dispatch(setIsLoadingSubmit(false))
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Document Updated Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);

        }
    }, [dispatch, fileList, form, formvalues?.area_of_expertise, formvalues?.client_type, formvalues?.geography, formvalues?.title, overview, questions, selectedCtaProjectId, userData?.data?._id, userData?.token])

    const applyProject = useCallback(async () => {
        const formData = new FormData()
        dispatch(setIsLoadingApply(true))
        questionAnswerInput?.forEach((qna, index) => {
            formData.append("questions", qna?.question)
            formData.append("answers", qna?.answer)
            formData.append("is_attachment", qna?.is_attachment)
            formData.append("attachements", answerAttachement[index]?.[0]?.originFileObj)
        })

        try {
            let res = await axios.post(`${API_URL.ADD_QUESTION_ANSWER}/${userDataExpert?.data?._id}?project_id=${selectedCtaProject?.project_id}`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userDataExpert?.token}`,
                    }
                })
            if (res) {
                dispatch(clearAll());
                dispatch(setIsLoadingApply(false))
                refetchDocList()
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Meeting Data Uploaded Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
                return res
            }
        } catch (error) {
            dispatch(setIsLoadingApply(false))
            console.error('error: ', error);
            return error

        }
    }, [answerAttachement, dispatch, form, questionAnswerInput, refetchDocList, selectedCtaProject?.project_id, userDataExpert?.data?._id, userDataExpert?.token])

    const acceptExpert = useCallback(async () => {
        dispatch(setIsLoadingAccept(true))
        console.log("hereeee", API_URL.APPROVE_EXPERT);
        try {
            let res = await axios.post(`${API_URL.APPROVE_EXPERT}/${ctaExpertQna?.expert_id}?project_id=${ctaExpertQna?.project_id}&user_id=${userData?.data?._id}`, {},
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(clearAll());
                dispatch(setOpenScreeningQuestionAdminModal(false))
                dispatch(setIsLoadingAccept(false))
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Expert Accepted For the Project!",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
                return res
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);
            return error

        }
    }, [ctaExpertQna?.expert_id, ctaExpertQna?.project_id, dispatch, form, userData?.data?._id, userDataExpert?.token])

    const rejectExpert = useCallback(async () => {
        dispatch(setIsLoadingReject(true))

        try {
            let res = await axios.post(`${API_URL.REJECT_EXPERT}/${ctaExpertQna?.expert_id}?project_id=${ctaExpertQna?.project_id}&user_id=${userData?.data?._id}`, {},
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(clearAll());
                dispatch(setOpenScreeningQuestionAdminModal(false))
                dispatch(setIsLoadingReject(false))
                setTimeout(() => {
                    notification.warning({
                        message: "Expert is rejected for the project",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
                return res
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);
            return error

        }
    }, [ctaExpertQna?.expert_id, ctaExpertQna?.project_id, dispatch, userData?.data?._id, userData?.token])

    const saveProject = useCallback(async (sel) => {

        try {
            let res = await axios.post(`${API_URL.SAVE_PROJECT}/${sel?._id}?project_id=${sel?.project_id}`, {},
                {
                    headers: {
                        // "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(clearAll());
                dispatch(setIsLoadingSubmit(false))
                refetchDocList()
                onlyOnce.current = false
                form?.resetFields();
                setTimeout(() => {
                    notification.success({
                        message: "Meeting Data Uploaded Successfully",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
                return res
            }
        } catch (error) {
            dispatch(setIsLoadingSubmit(false))
            console.error('error: ', error);
            return error

        }
    }, [dispatch, form, refetchDocList, userData?.token])



    const handleCTAProjectDelete = useCallback(async () => {
        dispatch(setIsLoadingDelete(true))
        try {
            let res = await axios.delete(`${API_URL.UPLOAD_CTA_PROJECT}/${selectedCtaProject?.project_id}?expert_id=${userDataExpert?.data?._id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    }
                })
            if (res) {
                dispatch(setIsLoadingDelete(false))
                dispatch(setIsOpenDelete(false))
                dispatch(setSelectedCTAProject({}))
                refetchDocList()
                setTimeout(() => {
                    notification.success({
                        message: "Document Deleted Successfully!",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }, 100);
            }
        } catch (error) {
            dispatch(setIsLoadingDelete(false))
            console.error('error: ', error);

        }
    }, [dispatch, refetchDocList, selectedCtaProject?.project_id, userData?.token, userDataExpert?.data?._id])



    const debounceKey = useMemo(() => {
        let loadOptions = async (value) => {
            dispatch(setKey(value))
        }
        return debounce(loadOptions, 800)
    }, [dispatch])


    const values = useMemo(
        () => ({
            uploadCTAProject, form, isLoadingCTAProject, acceptExpert, rejectExpert,
            updateCTAProject, onlyOnce, debounceKey, ctaProjectList, isLoadingCtaList, handleCTAProjectDelete, applyProject, saveProject, isLoadingExpertQuestion
        }),
        [uploadCTAProject, acceptExpert, rejectExpert, form, isLoadingCTAProject, updateCTAProject, debounceKey, ctaProjectList, isLoadingCtaList, handleCTAProjectDelete, applyProject, saveProject, isLoadingExpertQuestion]
    );
    return values;
}

export default useCTAList
