import { Button, Card, Divider, Form, Input, Modal, Spin, Typography, Upload } from 'antd'
import React from 'react'
import "../../assests/css/custom.css"
import { AiOutlinePaperClip } from "react-icons/ai";
import { DeleteOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAnswerAttachement, setQuestionAnswerInput, setSelectedCTAProject } from '../../pages/TeamProjects/features/projectCTA.slice';
import {

    LoadingOutlined,
} from "@ant-design/icons";
const ScreeningQuestionModal = ({ show, setShow, applyProject }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { selectedCtaProject, answerAttachement, questionAnswerInput, isLoadingApply } = useSelector(
        (state) => state.projectCTA
    );
    const handleInputChange = (e, que, id) => {
        let newQnaArray = [...questionAnswerInput]
        newQnaArray[id] = { question: newQnaArray[id]?.question || que, answer: e.target.value, is_attachment: [null, undefined]?.includes(newQnaArray[id]?.is_attachment) ? false : newQnaArray[id]?.is_attachment }
        dispatch(setQuestionAnswerInput(newQnaArray))
    };


    const handleRemoveFile = (file, id) => {
        let newFileArray = [...answerAttachement]
        let newQnaArray = [...questionAnswerInput]
        if (newFileArray?.length > 0) {
            newFileArray[id] = newFileArray?.[id]?.filter((item) => item.uid !== file.uid)
            dispatch(setAnswerAttachement(newFileArray))
        }
        if (newQnaArray?.length > 0) {
            newQnaArray[id] = { ...newQnaArray[id], is_attachment: false }
            dispatch(setQuestionAnswerInput(newQnaArray))
        }

    }

    return (
        <Modal
            title={<div>
                Screening Questions
                <Divider className="m-2" />
            </div>}
            open={show}
            centered
            width={1000}
            onCancel={() => {
                form.resetFields()
                dispatch(setQuestionAnswerInput([{ question: "", answer: "", is_attachment: false }]))
                dispatch(setSelectedCTAProject({}))
                setShow(false)
            }}
            okText={"Submit"}
            className="modal_scroll removeScrollbar"
            footer={false}
        >

            <Form
                form={form}
                layout="vertical"
                name="form_answer_question"
                onFinish={async () => {
                    await applyProject()
                    setShow(false)
                    form.resetFields()
                    dispatch(setQuestionAnswerInput([{ question: "", answer: "", is_attachment: false }]))
                    dispatch(setSelectedCTAProject({}))
                }}>
                <ol className='ps-4 '>
                    {selectedCtaProject?.questions?.map((que, index) => <li className='mb-4' key={index}>
                        <div key={index} style={{
                            fontSize: 20,

                        }} >{que}</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            border: "1px solid #d9d9d9",
                            borderRadius: "8px",
                            padding: "8px",
                            gap: "8px",
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                boxShadow: "none",
                                padding: "4px",
                                gap: "8px",
                            }}>
                                {/* Text Input */}
                                <Form.Item className='w-100' name={[index, "answer"]} rules={[
                                    {
                                        required: true,
                                        message: "Answer is required",
                                    },
                                ]}>
                                    <Input
                                        placeholder="Type your message here..."
                                        name={[index, "answer"]}
                                        value={questionAnswerInput?.[index]?.answer}
                                        onChange={(e) => {
                                            form.setFieldValue([index, "answer"], e.target.value)
                                            handleInputChange(e, que, index)
                                        }}
                                        style={{
                                            flex: 1,
                                            width: "95%",
                                            border: "none",
                                            boxShadow: "none",
                                        }}
                                    />
                                    {/* File Upload */}
                                    <Upload
                                        customRequest={async ({ file, onSuccess, onError }) => {
                                            // Simulate an upload request
                                            setTimeout(() => {
                                                onSuccess("ok"); // Simulate successful upload
                                            }, 1000);
                                        }}
                                        action={false}
                                        maxCount={1}
                                        fileList={answerAttachement[index]}
                                        onChange={({ fileList }) => {
                                            let list = fileList
                                                ?.map((file) => {
                                                    if (file?.size) {
                                                        const isLt10M = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB
                                                        const isPNG = file.type === "image/png";
                                                        const isJPEG = file.type === "image/jpeg";
                                                        const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                                        const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                                                        const isPDF = file.type === "application/pdf";
                                                        const isCSV = file.type === "text/csv";
                                                        if ((isPNG || isJPEG || isPDF || isWordsheet || isSpreadsheet || isCSV) && isLt10M) {
                                                            return file
                                                        }
                                                    } else {
                                                        return file;
                                                    }
                                                })
                                                ?.filter((li) => li !== undefined);
                                            let newFileArray = [...answerAttachement]
                                            newFileArray[index] = list
                                            dispatch(setAnswerAttachement(newFileArray))
                                            let newQnaArray = [...questionAnswerInput]
                                            newQnaArray[index] = { ...newQnaArray[index], is_attachment: true }
                                            dispatch(setQuestionAnswerInput(newQnaArray))
                                        }}
                                        beforeUpload={() => false} // Prevent automatic upload
                                        showUploadList={false} // Disable built-in upload list
                                    >
                                        <Button
                                            type="text"
                                            icon={<AiOutlinePaperClip style={{ fontSize: 18 }} />}
                                            style={{ border: "none", boxShadow: "none" }}
                                        />
                                    </Upload>
                                    {/* Send Button */}
                                </Form.Item>
                            </div>
                            {/* Uploaded Files Preview */}
                            {answerAttachement?.[index]?.length > 0 && (<div className='custom_list' style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                boxShadow: "none",
                                padding: "8px",
                                gap: "8px",
                            }}>
                                {answerAttachement?.[index]?.map((file, id) => (
                                    <Card className='g-2 me-2' key={id}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <Typography.Text ellipsis>{file.name}</Typography.Text>
                                            <Button
                                                type="text"
                                                className="ms-2"
                                                icon={<DeleteOutlined />}
                                                onClick={() => handleRemoveFile(file, index)}
                                                danger
                                            />
                                        </div>
                                    </Card>
                                ))}

                            </div>)}
                        </div>
                    </li>)}

                </ol >
                <div className="d-flex justify-content-end align-items-center">
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            class="secondaryBttn btn"
                            type="button"
                            onClick={() => {
                                form.resetFields()
                                dispatch(setQuestionAnswerInput([{ question: "", answer: "", is_attachment: false }]))
                                dispatch(setSelectedCTAProject({}))
                                setShow(false)
                            }}
                        >
                            Close
                        </button>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            className="viewButton btn ms-2"
                            type="submit"
                            htmlType="submit"
                        >
                            <> {isLoadingApply && <Spin
                                size="small"
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            color: "white",
                                            paddingRight: 5,
                                        }}
                                    />
                                }
                            />}
                                Submit</>
                        </button>
                    </Form.Item>
                </div>
            </Form>


        </Modal>
    )
}

export default ScreeningQuestionModal
