import { Card, Divider, Form, Input, Modal, Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from "@ant-design/icons";
import "../../assests/css/custom.css"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setOpenScreeningQuestionAdminModal } from '../../pages/TeamProjects/features/projectCTA.slice';
import useCTAList from '../../pages/CloudFeatures/InternalMeetings/hooks/useCTAList';

const ScreeningQuestionAdmin = ({ GetLiveProjectList }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { acceptExpert, rejectExpert } = useCTAList()
    const { openScreeningQuestionAdminModal, ctaExpertQna, isLoadingReject, isLoadingAccept, selectedExpertForScreening } = useSelector(
        (state) => state.projectCTA
    );

    return (
        <Modal
            title={<div>
                Screening Questions
                <Divider className="m-2" />
            </div>}
            open={openScreeningQuestionAdminModal}
            centered
            width={1000}
            onCancel={() => {
                dispatch(setOpenScreeningQuestionAdminModal(false))
            }}
            okText={"Submit"}
            className="modal_scroll removeScrollbar"
            footer={false}
        >

            <Form
                form={form}
                layout="vertical"
                name="form_answer_question"
                onFinish={async () => {
                    // await applyProject()

                }}>
                <ol className='ps-4 '>
                    {ctaExpertQna?.question_answers?.map((que, index) => <li className='mb-4' key={index}>
                        <div key={index} style={{
                            fontSize: 20,

                        }} >{que?.question}</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            border: "1px solid #d9d9d9",
                            borderRadius: "8px",
                            padding: "8px",
                            gap: "8px",
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                boxShadow: "none",
                                padding: "4px",
                                gap: "8px",
                            }}>
                                {/* Text Input */}

                                <Input
                                    placeholder="Type your message here..."
                                    name={[index, "answer"]}
                                    value={que?.answer}
                                    style={{
                                        flex: 1,
                                        width: "95%",
                                        border: "none",
                                        boxShadow: "none",
                                    }}
                                />

                            </div>
                            {/* Uploaded Files Preview */}
                            {que?.attachment?.length > 0 && (<div className='custom_list' style={{
                                display: "flex",
                                alignItems: "center",
                                border: "none",
                                boxShadow: "none",
                                padding: "8px",
                                gap: "8px",
                            }}>
                                {que?.attachment?.map((file, id) => (
                                    <Card className='g-2 me-2' key={id}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <a href={file?.s3url} target='_blank' rel="noreferrer">{file.file_original_name}</a>

                                        </div>
                                    </Card>
                                ))}

                            </div>)}
                        </div>
                    </li>)}

                </ol >
                <div className="d-flex justify-content-end align-items-center">
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            class="secondaryBttn btn"
                            type="button"
                            onClick={() => {
                                dispatch(setOpenScreeningQuestionAdminModal(false))

                            }}
                        >
                            Cancel
                        </button>
                    </Form.Item>
                    {!["approved", "rejected"]?.includes(selectedExpertForScreening?.marketplace_status) ? <>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <button
                                className="viewButton btn ms-2"
                                htmlType="button"
                                onClick={async () => {
                                    await acceptExpert()
                                    GetLiveProjectList()
                                }}
                            >
                                {isLoadingAccept && <Spin
                                    size="small"
                                    indicator={
                                        <LoadingOutlined
                                            style={{
                                                color: "white",
                                                paddingRight: 5,
                                            }}
                                        />
                                    }
                                />}
                                Accept
                            </button>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <button
                                className="viewButton btn ms-2"
                                htmlType="button"
                                onClick={async () => {
                                    await rejectExpert()
                                    GetLiveProjectList()
                                }}
                            >
                                {isLoadingReject && <Spin
                                    size="small"
                                    indicator={
                                        <LoadingOutlined
                                            style={{
                                                color: "white",
                                                paddingRight: 5,
                                            }}
                                        />
                                    }
                                />} Reject

                            </button>
                        </Form.Item></> : null}
                </div>
            </Form>


        </Modal>
    )
}

export default ScreeningQuestionAdmin
