import React, { useEffect } from "react";
import {
    Button,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Select,
    Space,
    Spin,
    Tooltip,
    Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import upload_icon from "../../../assests/images/upload_icon.png";
import Editor from "ckeditor5-custom-build/build/ckeditor";

// import useDocumentList from "../hooks/useDocumentList";
import { Country } from "country-state-city";
import { clearAll, setdeletedFileList, setFileList, setFormValues, setOpenProjectCTAModal, setOverview, setQuestions } from "../features/projectCTA.slice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import useCTAList from "../../CloudFeatures/InternalMeetings/hooks/useCTAList";
const { Dragger } = Upload;
const ProjectCTAModal = () => {

    const countries = Country?.getAllCountries();
    const { openProjectCTAModal, formvalues, type, questions, deletedfileList, fileList, isLoadingSubmit, overview, selectedCtaProject } = useSelector(
        (state) => state.projectCTA
    )

    const { uploadCTAProject, updateCTAProject, form, onlyOnce } = useCTAList()
    const dispatch = useDispatch();

    return (
        <Modal
            title={
                <span className="modalHeader">
                    {type === "create" ? "Publish To Experts" : "Publish To Experts"}
                </span>
            }
            footer={false}
            open={openProjectCTAModal}
            onOk={() => dispatch(setOpenProjectCTAModal(false))}
            onCancel={() => {
                dispatch(setOpenProjectCTAModal(false));
                dispatch(clearAll());
                onlyOnce.current = false
                form.resetFields();
            }}
            bodyStyle={{
                height: 500,
                overflowY: "scroll",
                overflowX: "hidden",
            }}
            width={1000}
            centered
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={formvalues}
                onFinish={() => {
                    if (type === "create") {
                        uploadCTAProject()
                    }
                    else {
                        updateCTAProject()
                    }
                }}
            >
                <Row>
                    <Col>
                        <Form.Item
                            label="Project Name"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Project Name is required",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Add Project Name"
                                name="title"
                                value={formvalues.title}
                                onChange={(e) => {
                                    let obj = {
                                        ...formvalues,
                                        title: e.target.value,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("title", e.target.value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Client Type"
                            name={"client_type"}
                            rules={[
                                {
                                    required: true,
                                    message: "Client type is required.",
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                style={{
                                    width: "100%",
                                }}
                                value={formvalues?.client_type}
                                placeholder="Client Type"
                                onChange={(value) => {
                                    let obj = {
                                        ...formvalues,
                                        client_type: value,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("subIndustry", value);
                                }}
                            >
                                <Select.Option value={"PSFs"}>PSFs</Select.Option>
                                <Select.Option value={"Corporates"}>Corporates</Select.Option>
                                <Select.Option value={"Investment Firms"}>
                                    Investment Firms
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>

                    <Col>
                        <Form.Item
                            label="Area of Expertise"
                            name={"area_of_expertise"}
                            rules={[{ required: true, message: "Area of Expertise are required" }]}
                        >
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                name="area_of_expertise"
                                size="large"
                                mode="tags"
                                allowClear
                                open={false}
                                placeholder="Add Area of Expertise"
                                value={formvalues?.area_of_expertise}
                                onChange={(value) => {
                                    let newVal = []
                                    value?.forEach((val) => {
                                        if (val?.includes(",")) {
                                            val?.split(",")?.map(item => newVal?.push(item?.trim())); // Split and trim any whitespace
                                        } else {
                                            newVal?.push(val)
                                        }
                                    })
                                    let obj = {
                                        ...formvalues,
                                        area_of_expertise: newVal,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("area_of_expertise", newVal)
                                }}
                                filterOption={(input, option) =>
                                    option?.children
                                        ?.toLowerCase()
                                        .indexOf(input?.toLowerCase()) >= 0
                                }
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Geography"
                            name="geography"
                            rules={[
                                {
                                    required: true,
                                    message: "Geography is required",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                value={formvalues.geography}
                                mode="multiple"
                                style={{ flex: 1, marginRight: "8px" }}
                                placeholder="Geography"
                                name="geography"
                                size="large"
                                virtual={false}
                                allowClear
                                onChange={(selectedValue) => {
                                    let obj = {
                                        ...formvalues,
                                        geography: selectedValue,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("geography", selectedValue);
                                }}
                                filterOption={(input, option) =>
                                    option?.props?.children
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <>
                                    <Select.Option key={0} value={"Global"} label={"Global"}>
                                        Global
                                    </Select.Option>
                                    <Select.Option key={1} value={"SAARC"} label={"SAARC"}>
                                        SAARC
                                    </Select.Option>
                                    <Select.Option key={2} value={"Asia"} label={"Asia"}>
                                        Asia
                                    </Select.Option>
                                    <Select.Option
                                        key={3}
                                        value={"Middle East"}
                                        label={"Middle East"}
                                    >
                                        Middle East
                                    </Select.Option>
                                    <Select.Option key={4} value={"Europe"} label={"Europe"}>
                                        Europe
                                    </Select.Option>
                                    <Select.Option
                                        key={5}
                                        value={"North America"}
                                        label={"North America"}
                                    >
                                        North America
                                    </Select.Option>
                                    <Select.Option
                                        key={6}
                                        value={"South America"}
                                        label={"South America"}
                                    >
                                        South America
                                    </Select.Option>
                                </>
                                {countries?.map((country, index) => (
                                    <Select.Option
                                        key={index + 8}
                                        value={country?.name}
                                        label={country?.name}
                                    >
                                        {`${country?.name}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Col className="projectEditor ck-body-wrapper">
                            <Form.Item
                                label="Project Overview"
                                name="project_overview"
                                rules={[
                                    { required: true, message: "Project overview is required" },
                                ]}
                            >
                                <CKEditor
                                    editor={Editor}
                                    data={overview || ""} // Prefill CKEditor with fetched overview data
                                    // onReady={(editor) => {
                                    //     // editorRef.current = editor;
                                    //     console.info("Editor is ready to use!", editor);
                                    // }}
                                    name="project_overview"
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "|",
                                            "outdent",
                                            "indent",
                                            "|",
                                            "undo",
                                            "redo",
                                        ],
                                        placeholder:
                                            "Please mention clearly the Purpose of the Project/End Goal, Profile of Expert, Target Companies, End Deliverable, Stage of Research, Resources Used, Update Expectations of the project.",
                                        // extraPlugins: 'autogrow',
                                        // autoGrow_bottomSpace: 0,
                                        autoGrow_minHeight: 800, // Set a fixed minimum height
                                        autoGrow_maxHeight: 500, // Set a fixed maximum height
                                    }}
                                    onChange={(event, editor) => {
                                        const newData = editor.getData();
                                        dispatch(setOverview(newData));
                                        form.setFieldValue("overview", newData);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Col>

                </Row>
                <Row>
                    <Form.Item rules={[{ required: true, message: "Documents are required" }]} label="Supporting Documents" name="attachement">
                        <Dragger
                            className="w-100 document_uploader"
                            action={false}
                            maxCount={1}
                            customRequest={async ({ file, onSuccess, onError }) => {
                                // Simulate an upload request
                                setTimeout(() => {
                                    onSuccess("ok"); // Simulate successful upload
                                }, 1000);
                            }}
                            beforeUpload={async (file) => {
                                const isLt10M = file.size / 1024 / 1024 < 10;
                                if (!isLt10M) {
                                    message.error({
                                        content: "File must be smaller than 10MB",
                                        style: {
                                            fontSize: "18px", // Increase the font size
                                            padding: "20px", // Increase the padding
                                        },
                                    });
                                    form.setFields([
                                        {
                                            name: "attachement",
                                            errors: [`File must be smaller than 10MB.`],
                                        },
                                    ]);
                                }
                                const isPNG = file.type === "image/png";
                                const isJPEG = file.type === "image/jpeg";
                                const isPDF = file.type === "application/pdf";
                                const isCSV = file.type === "text/csv";
                                if (!isPNG && !isJPEG && !isPDF && !isCSV) {
                                    form.setFields([
                                        {
                                            name: "attachement",
                                            errors: [`Please upload a file of a PDF, PNG, CSV or JPEG format.`],
                                        },
                                    ]);
                                }
                                return isPNG || isJPEG || isPDF || isCSV || isLt10M || Upload.LIST_IGNORE;
                            }}
                            onRemove={async (file) => {
                                dispatch(setdeletedFileList([...deletedfileList, file]));
                                dispatch(setFileList(fileList?.filter((f) => f.uid !== file.uid)));
                            }}
                            onChange={({ fileList }) => {
                                let list = fileList
                                    ?.map((file) => {
                                        if (file?.size) {
                                            const isLt10M = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB
                                            const isPNG = file.type === "image/png";
                                            const isJPEG = file.type === "image/jpeg";
                                            const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                                            const isPDF = file.type === "application/pdf";
                                            const isCSV = file.type === "text/csv";
                                            if ((isPNG || isJPEG || isPDF || isWordsheet || isSpreadsheet || isCSV) && isLt10M) {
                                                return file
                                            }
                                        } else {
                                            return file;
                                        }
                                    })
                                    ?.filter((li) => li !== undefined);
                                dispatch(setFileList(list))
                            }}
                            multiple={true}
                            fileList={fileList}
                        >
                            <img
                                src={upload_icon}
                                style={{ width: "60px" }}
                                alt="Upload Icon"
                            />
                            <p className="ant-upload-text pb-0 mt-4">
                                Please upload all relevant documents regarding the project
                            </p>
                            <small className="text-muted">
                                Maximum file size 10MB each
                            </small>
                        </Dragger>
                    </Form.Item>
                </Row>
                <Form.Item label="Screening Questions">
                    {questions?.map((qu, index) => (
                        <Row>
                            <Form.Item label={`Question #${index + 1}`} name={[index, "question"]}>
                                <Input placeholder="Question to ask"
                                    value={qu?.label}
                                    onChange={(e) => {
                                        let oldArray = [...questions];
                                        let newArray = oldArray?.map((input, i) => {
                                            if (i === index) {
                                                return {
                                                    ...input,
                                                    label: e.target.value,
                                                };
                                            }
                                            return input;
                                        });
                                        dispatch(setQuestions(newArray))
                                    }}
                                />
                            </Form.Item>
                        </Row>
                    ))}
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        <Button onClick={() => {
                            dispatch(setQuestions([...questions, { id: questions?.length + 1, label: "" }]))
                        }}>Add</Button>
                        <Button onClick={() => {
                            if (questions.length > 1) {
                                let allData = form.getFieldValue([questions?.length - 1]) || {}
                                form.setFieldsValue({
                                    [questions?.length - 1]: {
                                        ...allData,
                                        question: undefined,
                                    }
                                });
                                const newInputs = [...questions];
                                newInputs.splice(questions?.length - 1, 1);
                                dispatch(setQuestions(newInputs))

                            }
                        }}>Clear</Button>
                    </div>
                </Form.Item>

                <div className="d-flex justify-content-end align-items-center">
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            class="secondaryBttn btn"
                            type="button"
                            onClick={() => {
                                dispatch(setOpenProjectCTAModal(false));
                                dispatch(clearAll());
                                onlyOnce.current = false
                                form.resetFields();
                            }}
                        >
                            Close
                        </button>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            className="viewButton btn ms-2"
                            type="submit"
                            htmlType="submit"
                        >
                            {type === "create" ? <>Upload {isLoadingSubmit && <Spin
                                size="small"
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            color: "white",
                                            paddingRight: 5,
                                        }}
                                    />
                                }
                            />}</> : "Update"}
                        </button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
}

export default ProjectCTAModal;
