import { Button, Collapse, Popover, Tag, Tooltip } from "antd";
import _ from "lodash";
import React, { memo, useState } from "react";
import { FaEye, FaLinkedinIn } from "react-icons/fa6";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdContentCopy, MdDelete, MdOutlineAssignment, MdOutlineSaveAlt, MdWorkOutline } from "react-icons/md";
import { NavLink, } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";

import {
    LoadingOutlined
} from "@ant-design/icons";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsBuildings } from "react-icons/bs";
import { IoMdGlobe } from "react-icons/io";
import { LiaIndustrySolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import "../../assests/css/custom.css";
import user_icon from "../../assests/images/user.png";
import { modifiedContent } from "../../Utils/util";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useDispatch } from "react-redux";
import { setIsOpenDelete, setSelectedCTAProject } from "../../pages/TeamProjects/features/projectCTA.slice";
import { useSelector } from "react-redux";
const { Panel } = Collapse;
function ExpertProjectCard({ hit, index, show, setShow, saveProject }) {
    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()
    const convertDate = (date) => {
        if (date !== null && date !== "") {
            var d = new Date(date);
            var day = d.getDate();
            var x = d.toDateString().substr(4, 3);
            var year = d.getFullYear();
            let p = `${x} ${day}, ${year}`;
            return p;
        } else {
            return "---------";
        }
    };
    return (
        <>
            <div className="comon-divs-re findExperts expertBox pt-4 pb-4">
                <div
                    className="gap-2  d-xl-flex align-items-center justify-content-between "
                >
                    <NavLink
                        className="col-lg-9 position-relative"
                        style={{ cursor: "pointer" }}
                    >
                        {/* <Row className="g-0">
                        <Col md={11} > */}
                        {/* <div className="px-sm-3"> */}
                        <Row md={12}>
                            <Col md={12}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6
                                        className="mt-0"
                                        style={{ marginBottom: "5px" }}
                                    >
                                        {hit?.project_name}
                                    </h6>
                                    {/* <span>
                                    <button
                                        size="large"
                                        className="expertCardBtn"
                                        onClick={() => {
                                            setSelectedProject(hit)
                                            setShow(true)
                                        }}
                                    >
                                        <span style={{
                                            fontSize: "12px"
                                        }}>Screening Questions</span>
                                    </button>
                                </span> */}
                                </div>
                                <div>
                                    <div className="text-muted pe-4 mb-2 d-flex align-items-center g-3 gap-2">
                                        {![null, undefined, ""]?.includes(hit?.geography) ? <span>
                                            <Tooltip title="Country" color="#5746ec"> <IoMdGlobe style={{ color: "#5746ec" }} /></Tooltip>
                                            <span
                                                className="card-text"
                                                style={{ lineHeight: "13px" }}
                                            >
                                                <small
                                                    className="text-muted ms-1"
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    {hit?.geography?.map((countr, index) => (
                                                        <Tag className=""
                                                            color="purple"
                                                            key={index}>
                                                            {countr}
                                                        </Tag>
                                                    ))}
                                                </small>
                                            </span>
                                        </span> : null}
                                        {![null, undefined, ""]?.includes(hit?.client_type) ?
                                            <span>
                                                <Tooltip title="Client Type" color="#5746ec">
                                                    <MdWorkOutline style={{ color: "#5746ec" }} />
                                                </Tooltip>
                                                <span
                                                    className="card-text"
                                                >
                                                    <small
                                                        className="text-muted ms-1"
                                                        style={{ fontSize: "13px" }}
                                                    >
                                                        <Tooltip title={hit?.client_type} color="#5746ec">
                                                            {_?.truncate(hit?.client_type, { length: 30, omission: "..." })}
                                                        </Tooltip>
                                                    </small>
                                                </span>
                                            </span> : hit?.client_type}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {![null, undefined, ""]?.includes(hit?.current_company) ?
                                            <div className="text-muted pe-2 mb-2 d-flex align-items-center g-2 gap-1">
                                                <Tooltip title="Current Company" color="#5746ec"> <BsBuildings style={{ color: "#5746ec" }} /></Tooltip>
                                                <span
                                                    className="card-text"
                                                    style={{ lineHeight: "13px" }}
                                                >
                                                    <Tooltip title={hit?.current_company} color="#5746ec">
                                                        <Tag
                                                            color="green"
                                                        >
                                                            {_?.truncate(hit?.current_company, { length: 30, omission: "..." })}
                                                        </Tag>
                                                    </Tooltip>
                                                </span>
                                            </div> : null}
                                        {![null, undefined, ""]?.includes(hit?.previous_company) ?
                                            <div className="text-muted pe-2 mb-2 d-flex align-items-center g-2 gap-1">
                                                <Tooltip title="Previous Company" color="#5746ec"> <HiOutlineBuildingOffice2 style={{ color: "#5746ec" }} /></Tooltip>
                                                <span
                                                    className="card-text"
                                                    style={{ lineHeight: "13px" }}
                                                >

                                                    <Tooltip title={hit?.previous_company} color="#5746ec">
                                                        <Tag
                                                            color="yellow"
                                                        >
                                                            {_?.truncate(hit?.previous_company, { length: 30, omission: "..." })}
                                                        </Tag>
                                                    </Tooltip>
                                                </span>
                                            </div> : null}
                                    </div>

                                    {hit?.area_of_expertise && <div className="text-muted pe-4 mb-2 d-flex align-items-center g-2 gap-1">
                                        <Tooltip title="Expertise Tags" color="#5746ec"><LiaIndustrySolid style={{ color: "#5746ec" }} /></Tooltip>
                                        <span
                                            className="card-text d-flex align-items-center"
                                            style={{ lineHeight: "13px" }}
                                        >
                                            <>
                                                {hit?.area_of_expertise?.map((expertise, index) => (
                                                    <Tag
                                                        className=""
                                                        color="blue"
                                                        key={index}
                                                    >
                                                        {expertise}
                                                    </Tag>
                                                ))}

                                            </>
                                        </span>
                                    </div>}

                                </div>
                            </Col>
                        </Row>
                        {/* </div> */}
                        {/* </Col>
                    </Row> */}
                    </NavLink>
                    <div className="fixedCol-2 text-center">
                        <div className="d-flex gap-1 justify-content-center align-items-center">
                            <span>
                                <button
                                    size="large"
                                    className="expertCardBtn"
                                    onClick={() => {
                                        dispatch(setSelectedCTAProject(hit))
                                        setShow(true)
                                    }}
                                >
                                    <span style={{
                                        fontSize: "12px"
                                    }}>Apply</span>
                                </button>
                            </span>
                            <span>
                                <button
                                    size="large"
                                    className="expertCardBtn"
                                    onClick={() => {
                                        dispatch(setSelectedCTAProject(hit))
                                        saveProject(hit)
                                    }}
                                >
                                    <span style={{
                                        fontSize: "12px"
                                    }}> Save</span>
                                </button>
                            </span>
                            <span>
                                <button
                                    size="large"
                                    className="expertCardBtn"
                                    onClick={() => {
                                        dispatch(setSelectedCTAProject(hit))
                                        dispatch(setIsOpenDelete(true))
                                    }}
                                >
                                    <span style={{
                                        fontSize: "12px"
                                    }}> Decline</span>
                                </button>
                            </span>
                        </div>
                        {hit?.question_answer?.[0]?.status && <div className="d-flex align-items-center justify-content-center mt-2">
                            <Tag
                                className="me-0 m-2"
                                color={hit?.question_answer?.[0]?.status === "approved" ? "green" : hit?.question_answer?.[0]?.status === "rejected" ? "red" : "yellow"}
                                size="large"
                                bordered={true}
                            >
                                {hit?.question_answer?.[0]?.status}
                            </Tag>
                        </div>}
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <img
                                src="/images/expert-icon1.svg"
                                alt="sn"
                                width="17px"
                            />
                            <span
                                className="ps-2 card-text"
                                style={{ lineHeight: "13px" }}
                            >
                                <small
                                    className="text-muted"
                                    style={{ fontSize: "13px" }}
                                >
                                    {convertDate("2024-11-22T10:24:41.325Z")} - {convertDate("2024-11-24T10:24:41.325Z")}
                                </small>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <Collapse size="small" ghost className="ps-0">
                        <Panel
                            header={
                                <p type="link" className="p-0">
                                    Overview
                                </p>
                            }
                            key="1"
                        >
                            <p

                                dangerouslySetInnerHTML={{
                                    __html: modifiedContent(hit?.overview),
                                }}
                            ></p>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    )
}

export default memo(ExpertProjectCard);
