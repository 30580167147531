import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Space,
  Tag,
  message,
  Tooltip,
  Spin,
  notification,
} from "antd";
import API_PATH from "../../../Constants/api-path";
import company_icon3 from "../../../assests/images/company_icons3.png";
import { Spinner } from "react-bootstrap";
import "./Pages.css";
import user_icon from "../../../assests/images/user.png";
import ExpertDashboard from "../../components/ExpertDashboard";
import PhoneInput from "react-phone-input-2";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { CoPresent } from "@mui/icons-material";
import ProjectDetails from "../../../pages/Experts/ProjectDetails";

const { Option } = Select;

const text = <span>Industry</span>;
const text2 = <span>Tags</span>;
const text3 = <span>Email</span>;

const customColors = ["#5746ec"];

function ExpertProfile() {
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage.getItem("expertData"));
  const [form] = Form.useForm();
  const [Loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expertDetails, setExpertDetails] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [experienceDetails, setexperienceDetails] = useState([]);
  const [updateValue, setupdateValue] = useState({
    first_name: "",
    last_name: "",
    full_Name: "",
    name: "",
    contact_number: "",
    contact_code: "",
    hourly_rate_call_cunsltn: "",
    hourly_currency: "USD",
    linkedin_url: "",
    industry: "",
    tags: "",
    expert_id: userData?.data?._id,
  });

  updateValue.full_Name = `${updateValue?.first_name} ${updateValue?.last_name}`;
  updateValue.name = `${updateValue?.first_name} ${updateValue?.last_name}`;

  const [selectedIndustOptions, setSelectedIndustOptions] = useState([]);
  const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [phone, setPhone] = useState("");

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setupdateValue({ ...updateValue, [key]: value });
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    setupdateValue((prevStep3) => ({
      ...prevStep3,
      contact_code: country?.dialCode,
      contact_number: value.replace(country?.dialCode, ""), // Remove dial code from value
    }));
  };

  const expertCurrency = (value) => {
    const selectValue = value || "USD"; // Set "USD" if the value is falsy
    setupdateValue({ ...updateValue, hourly_currency: selectValue });
  };

  const hourly_rate = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setupdateValue({ ...updateValue, [key]: value });
  };

  const handleOptionIndustChange = (selectedValues) => {
    setSelectedIndustOptions(selectedValues);
    const selectValue = selectedValues;
    setupdateValue({ ...updateValue, industry: selectValue });
  };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    const selectValue = selectedValues;
    setupdateValue({ ...updateValue, tags: selectValue });
  };

  const GetIndustryList = async () => {
    try {
      const res = await fetch(`${API_PATH.ADD_EXPERT_INDUSTRY}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await res.json();
      if (result) {
        setFilteredIndustOptions(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const GetTagsList = async () => {
    try {
      const res = await fetch(`${API_PATH.ADD_EXPERT_TAG}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await res.json();
      if (result) {
        setFilteredOptions(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  useEffect(() => {
    GetIndustryList();
    GetTagsList();
  }, []);

  const GetExpertProfile = async () => {
    try {
      const res = await fetch(
        `${API_PATH.EXPERT_PROFILE_LIST}/${userData?.data?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setLoading(true);
        setExpertDetails(result?.data);
        setEducationDetails(result?.data[0]?.experteducations);
        setexperienceDetails(result?.data[0]?.expertexperiences);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  useEffect(() => {
    GetExpertProfile();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const EditProfile = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.EXPERT_PROFILE_UPDATE}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(updateValue),
      });

      const result = await res.json();
      if (result) {
        GetExpertProfile();
        if (res.status === 200 || res.status === true) {
          setShow(false);

          notification.success({
            message: "Profile has been updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const selectBefore = (
    <Select
      defaultValue="USD"
      size="large"
      value={updateValue?.hourly_currency || "USD"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
      onChange={expertCurrency}
    >
      <Select.Option value="USD">USD</Select.Option>
      <Select.Option value="EUR">EUR</Select.Option>
      <Select.Option value="JPY">JPY</Select.Option>
      <Select.Option value="GBP">GBP</Select.Option>
      <Select.Option value="AUD">AUD</Select.Option>
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="NZD">NZD</Select.Option>
      <Select.Option value="SGD">SGD</Select.Option>
      <Select.Option value="HKD">HKD</Select.Option>
    </Select>
  );

  if (Loading) {
    var expertRate = expertDetails[0]?.hourly_rate_call_cunsltn;
    var experience = [];
    for (var i = 0; i < experienceDetails?.length; i++) {
      experience?.push(experienceDetails[i]);
    }

    var CN = [];
    function uniqueData(data) {
      var companyName = [];
      for (var i = 0; i < data.length; i++) {
        if (companyName.indexOf(data[i].company_name) == -1) {
          companyName.push(data[i].company_name);
        }
      }
      CN.push(...companyName);
      return companyName;
    }

    uniqueData(experience);
    var store = [];
    for (let i = 0; i < CN.length; i++) {
      let local = [];
      let print = [];
      for (let j = 0; j < experience.length; j++) {
        if (CN[i] == experience[j].company_name) {
          let obj = {};
          obj.title = experience[j].company_name;
          obj.dateFrom = experience[j].date_from;
          obj.dateTill = experience[j].date_till
            ? experience[j].date_till
            : experience[j].till_present === "no"
              ? experience[j].date_from
              : "Present";
          obj.whatWork = experience[j].what_work;
          obj.location = experience[j].location;
          obj.tillPresent = experience[j].till_present;
          obj.logoUrl = experience[j].logo_url;
          obj.designation = experience[j].career_title;
          obj.timeE = new Date(obj.dateFrom).getTime();
          obj.timeS =
            obj.dateTill === "Present"
              ? new Date().getTime()
              : new Date(obj.dateTill).getTime();
          local.push(obj);
        }
        var min = Math.min(...local.map((item) => item.timeE));
        var max = Math.max(...local.map((item) => item.timeS));
      }

      var timed = {
        min: min,
        max: max,
      };

      print.push(
        CN[i],
        local.sort((a, b) => {
          return b.timeS - a.timeS;
        }),
        timed
      );

      store.push(print);
      store = store.sort((a, b) => {
        return b[1][0].timeS - a[1][0].timeS;
      });
    }
  }

  function expertSummery(data) {
    if (data !== null && data !== "" && data !== undefined) {
      return (
        <div className="p-4">
          <div className="pvs-header__title-container">
            <h2 className="pvs-header__title text-heading-large">
              <span aria-hidden="true">About </span>
            </h2>
          </div>
          <div className="inline-show-more-text">
            <span
              aria-hidden="true"
              className="jsEditContent"
              data-name="background_summery"
              style={{ fontSize: "15px", fontWeight: 400 }}
            >
              {data}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  const convertDate = (date) => {
    if (![null, undefined, ""]?.includes(date)) {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;

      return p;
    } else {
      return "---------";
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const Convert_Month_Year = (months) => {
    var month = months + 1;

    var year = Math.floor(month / 12);
    if (year === 0) {
      var month_year = (month % 12) + " mos";
      return month_year;
    } else if (month % 12 === 0) {
      var month_year = year + " yrs ";
      return month_year;
    } else {
      var month_year = year + " yrs " + (month % 12) + " mos";
      return month_year;
    }
  };

  const setUserModal = ({ data, id }) => {
    if (!data) {
      console.error("data is null or undefined:", data);
      return;
    }

    let dataArray = Array.isArray(data) ? data : [data]; // Convert to array if it's a single object
    const res = dataArray?.find((val) => val?._id === id);

    if (res) {
      setupdateValue((prevValue) => ({
        ...prevValue,
        first_name: res?.first_name || "",
        last_name: res?.last_name || "",
        hourly_rate_call_cunsltn: res?.hourly_rate_call_cunsltn || "",
        hourly_currency: res?.hourly_currency || "USD",
        linkedin_url: res?.linkedin_url || "",
        industry: !Array.isArray(res?.industry)
          ? res?.industry?.split(",")
          : [],
        tags: !Array.isArray(res?.tags) ? res?.tags?.split(",") : [],
        contact_number: res?.contact_number || "",
        contact_code: res?.contact_code || "", // Assuming res.contact_code contains the dial code
      }));

      // Set phone including contact code
      setPhone(`+${res?.contact_code}${res?.contact_number}`);
    } else {
      console.error("No matching object found with id:", id);
    }
  };

  const ConstantsArray = (array) => {
    var type = typeof array;
    let newArray = array;
    if (type == "string") {
      newArray = array?.split(",");
    }
    return newArray;
  };

  if (!Loading) {
    return (
      <div
        className={
          Loading
            ? "text-center loginScreenLinkedin active"
            : "text-center loginScreenLinkedin"
        }
      >
        <Spin size="large" />
      </div>
    );
  } else {
    return (
      <>
        <ExpertDashboard />
        <div className="page-wrapper chiller-theme toggled">
          <div className="main-sec d-lg-flex">
            <Sidebar />
          </div>
          <main className="body-total content-wrapper float-start w-100">
            <div className="container-fluid">
              <Row className="justify-content-center marginT">
                <Col xl={11}>
                  <Row>
                    <Col>
                      <h5
                        className="d-flex align-items-center mt-0"
                        style={{ fontWeight: 600, fontSize: "26px" }}
                      >
                        <NavLink
                          className="pe-3 bckIcon"
                          style={{ cursor: "pointer" }}
                          to={"/expert/dashboard"}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 320 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path>
                          </svg>
                        </NavLink>

                        <span>Profile</span>
                      </h5>
                    </Col>
                  </Row>
                  <Row className="g-3 pb-5">
                    <Col>
                      <div className="bg-white expertBox mb-3">
                        <div className="p-4">
                          <div class="d-flex justify-content-end align-items-end text-end">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              class="me-2"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={() => {
                                setShow(true);
                                setUserModal({
                                  data: expertDetails[0],
                                  id: expertDetails[0]?._id,
                                });
                              }}
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41z"></path>
                            </svg>
                          </div>

                          <div className="g-0 d-md-flex justify-content-between">
                            <div
                              className="fixedWidthCol mx-auto mx-md-0 position-relative"
                              style={{ width: "150px" }}
                            >
                              {expertDetails[0]?.image !== null &&
                                expertDetails[0]?.image !== "" &&
                                expertDetails[0]?.image ? (
                                <>
                                  <img
                                    src={expertDetails[0]?.image}
                                    alt={expertDetails[0]?.first_name}
                                    style={{
                                      borderRadius: "50%",
                                      width: "100%",
                                    }}
                                    onError={addDefaultSrc}
                                  />
                                </>
                              ) : (
                                <img
                                  src={user_icon}
                                  alt={expertDetails[0]?.first_name}
                                  style={{
                                    borderRadius: "50%",
                                    width: "100%",
                                  }}
                                  loading="lazy"
                                />
                              )}
                            </div>
                            <Row className="d-md-none">
                              <Col className="text-center">
                                <div className="mt-3">
                                  {expertDetails[0]?.name !== null &&
                                    expertDetails[0]?.name !== undefined &&
                                    expertDetails[0]?.name !== "" ? (
                                    <h3 className="find_companyName mb-0 mt-0">
                                      {expertDetails[0]?.name}
                                    </h3>
                                  ) : (
                                    <h3 className="find_companyName mb-0 mt-0">
                                      {expertDetails[0]?.first_name}{" "}
                                      {expertDetails[0]?.last_name}
                                    </h3>
                                  )}

                                  <div className="d-flex justify-content-center">
                                    {expertDetails[0]?.country !== null &&
                                      expertDetails[0]?.country !== undefined ? (
                                      <div className="text-muted pe-4">
                                        <i
                                          class="las la-globe-americas me-2"
                                          style={{ color: "#5746ec" }}
                                        ></i>
                                        <span className="card-text">
                                          <small className="text-muted">
                                            {expertDetails[0]?.country}
                                          </small>
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <div className="position-relative mt-4 mt-md-0">
                              <div>
                                <h5 style={{ fontWeight: 600 }} className="m-0">
                                  {expertDetails[0]?.hourly_currency
                                    ? expertDetails[0]?.hourly_currency
                                    : "USD"}{" "}
                                  {expertDetails[0]?.hourly_rate_call_cunsltn
                                    ? expertDetails[0]?.hourly_rate_call_cunsltn
                                    : "250"}
                                  /
                                  <small className="text-muted fw-normal">
                                    {expertDetails[0]?.charge_duration ||
                                      "hour"}
                                  </small>
                                </h5>
                                <h5 className="my-1">
                                  <svg
                                    style={{
                                      fontSize: "20px",
                                      color: "rgb(87, 70, 236)",
                                    }}
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 1024 1024"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
                                  </svg>
                                  <span
                                    className="ps-3"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Since{" "}
                                    {convertDate(
                                      expertDetails[0]?.createdAt
                                        ? expertDetails[0]?.createdAt
                                        : expertDetails[0]?.created_at
                                    )}
                                  </span>
                                </h5>
                                <h5 className="my-1">
                                  <svg
                                    style={{
                                      fontSize: "20px",
                                      color: "rgb(87, 70, 236)",
                                    }}
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 512 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="32"
                                      d="M320 96H88a40 40 0 00-40 40v240a40 40 0 0040 40h334.73a40 40 0 0040-40V239"
                                    ></path>
                                    <path
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="32"
                                      d="M112 160l144 112 87-65.67"
                                    ></path>
                                    <circle
                                      cx="431.95"
                                      cy="128.05"
                                      r="47.95"
                                    ></circle>
                                    <path d="M432 192a63.95 63.95 0 1163.95-63.95A64 64 0 01432 192zm0-95.9a32 32 0 1031.95 32 32 32 0 00-31.95-32z"></path>
                                  </svg>
                                  <span
                                    className="ps-3"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {expertDetails[0]?.email}
                                  </span>
                                </h5>
                                <h5 className="my-1">
                                  <svg
                                    style={{
                                      fontSize: "20px",
                                      color: "rgb(87, 70, 236)",
                                    }}
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    version="1.2"
                                    baseProfile="tiny"
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8 19h-3v-10h3v10zm11 0h-3v-5.342c0-1.392-.496-2.085-1.479-2.085-.779 0-1.273.388-1.521 1.165v6.262h-3s.04-9 0-10h2.368l.183 2h.062c.615-1 1.598-1.678 2.946-1.678 1.025 0 1.854.285 2.487 1.001.637.717.954 1.679.954 3.03v5.647z"></path>
                                    <ellipse
                                      cx="6.5"
                                      cy="6.5"
                                      rx="1.55"
                                      ry="1.5"
                                    ></ellipse>
                                  </svg>
                                  <span
                                    className="ps-3"
                                    style={{ fontSize: "15px" }}
                                  >
                                    <a
                                      href={expertDetails[0]?.linkedin_url}
                                      target="_blank"
                                      style={{
                                        color: "#5746ec",
                                        fontWeight: 500,
                                      }}
                                    >
                                      View
                                    </a>
                                  </span>
                                </h5>

                                {expertDetails[0]?.company !== null &&
                                  expertDetails[0]?.company !== undefined ? (
                                  <h5 className="my-1">
                                    <svg
                                      style={{
                                        fontSize: "20px",
                                        color: "rgb(87, 70, 236)",
                                      }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 16 16"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"></path>
                                      <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"></path>
                                    </svg>
                                    <span
                                      className="ps-3"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {expertDetails[0]?.company}
                                    </span>
                                  </h5>
                                ) : (
                                  <></>
                                )}

                                {expertDetails[0]?.contact_number === null ||
                                  expertDetails[0]?.contact_number ===
                                  undefined ||
                                  expertDetails[0]?.contact_number ===
                                  "" ? null : (
                                  <h5 className="my-1">
                                    <svg
                                      style={{
                                        fontSize: "20px",
                                        color: "rgb(87, 70, 236)",
                                      }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0V0z"
                                      ></path>
                                      <path
                                        fill-opacity=".3"
                                        d="M16.49 4.5c1.79 0 3.42.73 4.59 1.91l1.06-1.06A7.94 7.94 0 0016.49 3c-2.21 0-4.21.9-5.66 2.34l1.06 1.06a6.545 6.545 0 014.6-1.9z"
                                      ></path>
                                      <path
                                        fill-opacity=".3"
                                        d="M16.49 6c-1.38 0-2.63.56-3.54 1.46l1.06 1.06c.63-.63 1.51-1.03 2.47-1.03s1.84.39 2.47 1.03l1.06-1.06A4.94 4.94 0 0016.49 6z"
                                      ></path>
                                      <path d="M16.49 9c-.55 0-1.05.22-1.41.59L16.49 11l1.41-1.41c-.36-.37-.86-.59-1.41-.59z"></path>
                                      <path
                                        d="M15 17.83c1.29.54 2.63.89 4 1.07v-2.23l-2.35-.47L15 17.83zM7.33 5H5.1c.18 1.37.53 2.7 1.07 4L7.8 7.35 7.33 5z"
                                        opacity=".3"
                                      ></path>
                                      <path d="M20.2 14.87l-3.67-.73c-.5-.1-.83.2-.9.27l-2.52 2.5c-2.5-1.43-4.57-3.5-6-6l2.5-2.52c.23-.24.33-.57.27-.9L9.13 3.8c-.09-.46-.5-.8-.98-.8H4c-.56 0-1.03.47-1 1.03.17 2.89 1.05 5.6 2.43 7.97 1.58 2.73 3.85 4.99 6.57 6.57 2.37 1.37 5.08 2.26 7.97 2.43.55.03 1.03-.43 1.03-1v-4.15c0-.48-.34-.89-.8-.98zM5.1 5h2.23l.47 2.35L6.17 9c-.54-1.3-.9-2.63-1.07-4zM19 18.9c-1.37-.18-2.7-.53-4-1.07l1.65-1.63 2.35.47v2.23z"></path>
                                    </svg>
                                    <span
                                      className="ps-3"
                                      style={{ fontSize: "15px" }}
                                    >
                                      {expertDetails[0]?.contact_code &&
                                        !expertDetails[0]?.contact_code.includes(
                                          "+"
                                        )
                                        ? `+${expertDetails[0]?.contact_code}`
                                        : expertDetails[0]?.contact_code}{" "}
                                      {expertDetails[0]?.contact_number}
                                    </span>
                                  </h5>
                                )}
                              </div>
                            </div>
                          </div>
                          <Row className="d-none d-md-block">
                            <Col>
                              <div className="mt-3">
                                {expertDetails[0]?.name !== null &&
                                  expertDetails[0]?.name !== undefined &&
                                  expertDetails[0]?.name !== "" ? (
                                  <h3 className="find_companyName mb-0 mt-0">
                                    {expertDetails[0]?.name}
                                  </h3>
                                ) : (
                                  <h3 className="find_companyName mb-0 mt-0">
                                    {expertDetails[0]?.first_name}{" "}
                                    {expertDetails[0]?.last_name}
                                  </h3>
                                )}

                                <div className="d-flex">
                                  {expertDetails[0]?.country !== null &&
                                    expertDetails[0]?.country !== undefined ? (
                                    <div className="text-muted pe-4">
                                      <i
                                        class="las la-globe-americas me-2"
                                        style={{ color: "#5746ec" }}
                                      ></i>
                                      <span className="card-text">
                                        <small className="text-muted">
                                          {expertDetails[0]?.country}
                                        </small>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {expertDetails[0]?.cmp_title && (
                            <Row className="mt-2 d-none d-md-block">
                              <Col>
                                <p className="mt-0 text-wrap-afte-three-line">
                                  {expertDetails[0]?.cmp_title}
                                </p>
                              </Col>
                            </Row>
                          )}
                          <Row className="mt-4 mt-md-2">
                            <Col>
                              {expertDetails[0]?.industry !== null &&
                                expertDetails[0]?.industry !== undefined ? (
                                <>
                                  {customColors?.map((color) => (
                                    <Tooltip
                                      placement="top"
                                      color={color}
                                      title={text}
                                    >
                                      <svg
                                        style={{
                                          fontSize: "20px",
                                          color: "rgb(87, 70, 236)",
                                        }}
                                        stroke="currentColor"
                                        fill="currentColor"
                                        stroke-width="0"
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g>
                                          <path
                                            fill="none"
                                            d="M0 0h24v24H0z"
                                          ></path>
                                          <path d="M10 10.111V1l11 6v14H3V7l7 3.111zm2-5.742v8.82l-7-3.111V19h14V8.187L12 4.37z"></path>
                                        </g>
                                      </svg>
                                    </Tooltip>
                                  ))}

                                  <span
                                    className="ps-3"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {ConstantsArray(
                                      expertDetails[0]?.industry
                                    )?.map((curElem, id) => {
                                      // Added By Dharmendra
                                      return (
                                        <>
                                          {
                                            <Tag
                                              color="blue"
                                              bordered={false}
                                              key={id}
                                            >
                                              {curElem}
                                            </Tag>
                                          }
                                        </>
                                      );
                                    })}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col>
                              {expertDetails[0]?.tags !== null &&
                                expertDetails[0]?.tags !== undefined ? (
                                <>
                                  {customColors.map((color) => (
                                    <Tooltip
                                      placement="top"
                                      color={color}
                                      title={text2}
                                    >
                                      <svg
                                        style={{
                                          fontSize: "20px",
                                          color: "rgb(87, 70, 236)",
                                        }}
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-width="1.5"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                                        ></path>
                                      </svg>
                                    </Tooltip>
                                  ))}

                                  <span
                                    className="ps-3"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {ConstantsArray(
                                      expertDetails[0]?.tags
                                    )?.map((curElem, id) => {
                                      return (
                                        <>
                                          <Tag
                                            color="volcano"
                                            bordered={false}
                                            key={id}
                                          >
                                            {curElem}
                                          </Tag>
                                        </>
                                      );
                                    })}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {isLoading ? (
                        <div style={{ textAlign: "center" }}>
                          <Spin animation="border" />
                        </div>
                      ) : (
                        <>
                          {
                            store?.length > 0 && (
                              <div className="mt-4">
                                <div className="bg-white expertBox">
                                  <div className="p-4">
                                    <div className="pvs-header__title-container">
                                      <h2 className="pvs-header__title text-heading-large">
                                        <span aria-hidden="true">
                                          Experience
                                        </span>
                                      </h2>
                                    </div>
                                    <div className="inline-show-more-text">
                                      {store?.map((curElem, id) => {
                                        return (
                                          <>
                                            <div className="companies" key={id}>
                                              <div className="companyLogo">
                                                {curElem[1][0]?.logoUrl !==
                                                  null &&
                                                  curElem[1][0]?.logoUrl !==
                                                  undefined &&
                                                  curElem[1][0]?.logoUrl !==
                                                  "" ? (
                                                  <img
                                                    src={curElem[1][0]?.logoUrl}
                                                    alt={curElem[1][0]?.logoUrl}
                                                    onError={addDefaultSrc}
                                                  />
                                                ) : (
                                                  <img
                                                    src={company_icon3}
                                                    alt={"nextyn"}
                                                    loading="lazy"
                                                  />
                                                )}
                                                <div
                                                  style={{ marginLeft: "20px" }}
                                                >
                                                  <h5 className="companyName">
                                                    {curElem[0]}
                                                  </h5>
                                                  {curElem[1][0].tillPresent ===
                                                    "yes" ? (
                                                    <p className="totalExperience">
                                                      {/* Calculate duration from dateFrom to current date */}
                                                      {Convert_Month_Year(
                                                        getMonthDifference(
                                                          new Date(
                                                            curElem[2]?.min
                                                          ),
                                                          new Date(Date.now())
                                                        )
                                                      )}
                                                    </p>
                                                  ) : (
                                                    <p className="totalExperience">
                                                      {/* Calculate duration from dateFrom to dateTill */}
                                                      {curElem[1][0]
                                                        ?.experience !==
                                                        "Less than a year"
                                                        ? Convert_Month_Year(
                                                          getMonthDifference(
                                                            new Date(
                                                              curElem[2]?.min
                                                            ),
                                                            new Date(
                                                              curElem[2]?.max
                                                            )
                                                          )
                                                        )
                                                        : curElem[1][0]
                                                          ?.dateFrom +
                                                        " - " +
                                                        curElem[1][0]
                                                          ?.experience}
                                                    </p>
                                                  )}
                                                  <p className="text-body-small inline t-black--light break-words"></p>
                                                </div>
                                              </div>
                                              <div className="experienceWrapper">
                                                {curElem[1].map((exp, id) => {
                                                  return (
                                                    <div
                                                      className="experienceInfo"
                                                      key={id}
                                                    >
                                                      <h5 className="companyName">
                                                        {exp?.designation}
                                                      </h5>
                                                      <p
                                                        className="text-body-small inline t-black--light break-words"
                                                        style={{
                                                          fontWeight: 500,
                                                        }}
                                                      >
                                                        {exp?.experience !==
                                                          "Less than a year" &&
                                                          exp?.dateFrom +
                                                          " - " +
                                                          (exp?.dateTill ===
                                                            null ||
                                                            exp?.dateTill ===
                                                            "" ||
                                                            exp?.dateTill === 1
                                                            ? "Present"
                                                            : exp?.dateTill)}
                                                      </p>
                                                      {exp?.location ? (
                                                        <p className="text-body-small inline t-black--light break-words mb-3">
                                                          {exp?.location}
                                                        </p>
                                                      ) : null}
                                                      <div>
                                                        {exp?.whatWork !==
                                                          null ? (
                                                          <p className="experienceContent">
                                                            {exp?.whatWork}
                                                          </p>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                            //       : (
                            //         <>
                            //           <div className="text-center noResultFound">
                            //             <div className="container">
                            //               <div className="row d-flex justify-content-center align-items-center">
                            //                 <div className="col-md-6">
                            //                   <svg
                            //                     className="SVGInline-svg StyledManWithIceCream--1fwimz7-svg dRWpfu-svg"
                            //                     fill="none"
                            //                     xmlns="http://www.w3.org/2000/svg"
                            //                     viewBox="0 0 179 250"
                            //                     style={{
                            //                       height: "400px",
                            //                       marginTop: "50px",
                            //                     }}
                            //                   >
                            //                     <path
                            //                       fill-rule="evenodd"
                            //                       clip-rule="evenodd"
                            //                       d="M148.017 226.599c0 .267-.012.532-.035.794l12.858-1.387.273-.003c.737-.013 1.774-.032.317.601-1.27.552-6.004 1.67-9.594 2.518-1.958.462-3.576.844-4.105 1.01 1.489.359 7.101.845 12.527 1.314 5.967.517 11.707 1.013 11.487 1.3-.184.241-3.263.097-7.029-.078-5.381-.251-12.165-.567-13.899.078-1.706.635 3.43 2.687 7.759 4.415 3.155 1.261 5.882 2.35 5.214 2.593-1.179.43-11.424-3.108-16.628-4.905-1.783-.616-2.974-1.027-3.007-1.005-.144.096.891 1.41 1.989 2.805h.001c1.304 1.657 2.698 3.428 2.313 3.404-.252-.016-1.971-1.515-3.714-3.037-1.774-1.548-3.574-3.119-3.882-3.172a58.093 58.093 0 0 1-2.429-.493c-.267-.058-1.868.721-3.416 1.475-1.448.705-2.849 1.388-3.071 1.342-.487-.101.907-1.333 1.99-2.29.706-.624 1.281-1.132 1.114-1.132l-.107.001c-2.12 0-9.231.001-10.653-1.118-.93-.732.567-1.075 2.448-1.505 2.195-.503 4.913-1.125 4.913-2.624 0-4.784 4.487-8.667 9.211-8.667 4.723 0 7.155 2.982 7.155 7.766zm17.953 2.59c-.683-.007-3.645-.583-1.97-.889 1.675-.306 5.508-.306 6.098.292.59.597-3.444.605-4.128.597zm9.878 5.102c-.495.187.953.687 1.636.716.683.029 1.668-.278.684-.642-.984-.363-1.825-.261-2.32-.074zm-7.616 4.744c-.431-.092-4.311-1.505-3.195-1.597 1.116-.092 3.539.385 3.799 1.045.261.66-.174.644-.604.552z"
                            //                       fill="#FF6DA2"
                            //                     ></path>
                            //                     <path
                            //                       d="M106.952 62.373c2.428-.895-12.124-19.7-12.92-20.15-.795-.452-8.543 8.516-8.278 9.536.265 1.02 18.77 11.51 21.198 10.614z"
                            //                       fill="#E0CF77"
                            //                     ></path>
                            //                     <path
                            //                       d="M93.962 42.196c.573.332-.074 3.5-2.27 5.858-2.196 2.357-5.524 4.622-6.307 3.36-.784-1.261.377-2.686 2.573-5.043 2.196-2.357 5.43-4.508 6.004-4.175z"
                            //                       fill="#C59F65"
                            //                     ></path>
                            //                     <path
                            //                       d="M15.703 240.685c6.61 0 12.264-.465 9.519-3.205-2.542-2.539-19.334-8.147-22.122-4.066-1.54 2.254-3.159 6.76.45 6.976 3.61.217 11.055.295 12.153.295zM94.52 222.9c-3.087-.332-9.28 1.602-11.99 2.611 0 0-7.586.477-8.235 3.99-.496 2.685-.152 7.459 3.236 6.198 3.388-1.261 10.276-4.104 11.233-4.642 7.27-4.094 9.614-7.742 5.755-8.157z"
                            //                       fill="#1A2460"
                            //                     ></path>
                            //                     <path
                            //                       d="M65.97 122.111c-7.762 3.103-26.039 1.293-33.477 0 0 6.787-1.756 9.189-5.636 9.189-7.115 27.145-22.395 90.669-23.947 102.302 1.294 2.586 5.433 3.103 7.761 0 2.329-3.102 24.9-46.535 35.895-67.864 7.438 22.945 24.819 65.475 28.7 64.7 3.88-.776 6.647-3.01 7.294-3.979L65.97 122.111z"
                            //                       fill="#7482DE"
                            //                     ></path>
                            //                     <path
                            //                       d="M77.55 73.541c-4.657.776-7.114-7.433-7.761-11.634l-6.791 1.94v9.694c1.617 6.14 6.791 17.45 14.552 13.573 6.344-3.17 17.847-13.669 22.252-27.1-4.31-3.98-2.653-5.306-3.979-8.621-.995-1.327-2.159 5.05-2.321 5.968-1.229 6.962-11.295 15.404-15.952 16.18zm22.712-22.454c-.663 0-.995-.663-.663-1.99 1.326-.331 3.647 2.653 3.979 3.98.331 1.325.864 2.294.201 2.626-.317.158-.814-.411-.995-.995-.197-.637-.081-1.3-.533-1.3-.331 0-1.124-.689-1.124-1.352 0-.663-.202-.97-.865-.97zm-46.845-27.66c-.876 1.768-5.174 6.95-6.998 8.424l-.663 2.627c6.202 3.683 18.167 10.166 16.416 6.63-1.75-3.536-1.459-6.63-1.094-7.735 2.554-2.21 7.661-6.851 7.661-7.735 0-.885 1.46-1.842 2.189-2.21-.876-1.768.73-7.367 1.094-8.84l-10.944-6.63-7.661 6.63c.365 2.21.875 7.072 0 8.84z"
                            //                       fill="#DD6652"
                            //                     ></path>
                            //                     <path
                            //                       d="M57.794 19.317c-.875 1.768-3.948 4.777-5.407 5.882 0-1.104 1.03-4.775-1.16-8.091l-.001-.003c-2.189-3.315-4.375-6.628-1.093-7.732 3.284-1.105 1.095-7.735 3.284-6.63 2.188 1.105 3.283-3.316 6.566-1.106 3.284 2.21 6.567-3.314 7.661-1.104 1.095 2.21 2.19 4.42 4.378 4.42 2.189 0 4.378 4.42 3.283 5.524-.875.884 1.095 3.315-1.094 4.42-2.189 1.106-4.914 1.018-6.572-.64-1.658-1.657-3.865.247-5.637.64-1.663.37-1.51-2.508-2.984.687-.652 1.412-.13 1.523-1.224 3.733z"
                            //                       fill="#1A2460"
                            //                     ></path>
                            //                     <path
                            //                       d="M57.795 29.264c-1.094-3.315 3.284-9.945 4.378-11.05 1.095-1.105 2.554-2.579 2.189-1.105-.365 1.473-1.313 3.757-2.189 5.525-1.094 2.21 0 3.315 1.095 4.42.875.884 4.013-.369 5.472-1.105-.365 1.841-1.314 5.967-2.19 7.735-1.094 2.21-3.282 3.315-6.566 2.21-3.283-1.105-1.094-3.315-2.189-6.63z"
                            //                       fill="#1A2460"
                            //                     ></path>
                            //                     <path
                            //                       d="M57.465 14.395c.36-1.989 2.879-2.79 3.034 0-.443 1.138-1.294 2.924-1.441 3.433-.184.638-.176.74-.704.74-.469 0-.768-.867-.663-1.327.216-.943-.412-1.814-.226-2.846z"
                            //                       fill="#DD6652"
                            //                     ></path>
                            //                     <path
                            //                       d="M64.03 65.881c1.552 13.185 1.94 42.981 1.94 56.231-12.417 4.653-31.044 1.292-38.805-.97 4.527-17.755 13.776-53.275 14.552-53.322-3.88.324-12.612-.194-16.492-4.847 1.293-8.08 7.373-25.595 21.343-31.024.97 2.909 6.567 8.834 15.104 8.834 5.433 4.653 7.209 15.403 9.15 21.22-.971.97-3.688 3.103-6.792 3.878z"
                            //                       fill="#FDC8DA"
                            //                     ></path>
                            //                     <path
                            //                       d="M28.134 133.745c-1.553-4.653-1.617-47.181-1.94-68.833 2.586 1.615 9.119 5.041 14.551 5.817-3.557 15.188-9.035 48.724-5.93 57.255 3.104 8.532-3.448 7.054-6.681 5.761z"
                            //                       fill="#DD6652"
                            //                     ></path>
                            //                     <path
                            //                       d="M41.717 67.82c-8.537 0-14.552-3.231-16.492-4.847v4.847c2.587 1.94 9.313 5.623 15.522 4.848l.97-4.848zm29.103-5.817c-3.105 3.103-5.821 3.878-6.791 3.878l-.97-3.878c1.617-.323 5.238-1.357 6.79-2.908l.97 2.908z"
                            //                       fill="#EBA3BF"
                            //                     ></path>
                            //                     <path
                            //                       d="M3.28 142.491c4.76-2.418 57.613-4.307 59.561 0 1.949 4.307 3.647 39.117 0 41.842-3.646 2.726-57.613 4.307-59.561 0-1.948-4.307-4.76-39.424 0-41.842z"
                            //                       fill="#333"
                            //                     ></path>
                            //                     <path
                            //                       d="M28.048 131.377c-4.576-.897-8.32 6.281-6.24 9.87h4.16c-.693-2.243-1.248-6.28 2.08-6.28 4.16 0 7.904 0 8.736 1.345.923 1.494.555 4.038.832 4.935h4.16c-.416-13.01-9.152-8.973-13.728-9.87z"
                            //                       fill="#333"
                            //                     ></path>
                            //                     <path
                            //                       d="M37.137 132.957c0 2.015-2.375 3.648-5.305 3.648-2.93 0-5.305-1.633-5.305-3.648 0-2.014 1.05-6.962 3.98-6.962 2.929 0 6.63 4.948 6.63 6.962z"
                            //                       fill="#DD6652"
                            //                     ></path>
                            //                   </svg>
                            //                 </div>
                            //                 <div className="col-md-6">
                            //                   <h3
                            //                     style={{
                            //                       fontSize: "18px",
                            //                       textAlign: "left",
                            //                       fontWeight: "300",
                            //                       lineHeight: "26px",
                            //                     }}
                            //                   >
                            //                     Our quality check team is currently
                            //                     reviewing your profile.
                            //                   </h3>
                            //                   <h3
                            //                     style={{
                            //                       fontSize: "18px",
                            //                       textAlign: "left",
                            //                       fontWeight: "300",
                            //                       lineHeight: "26px",
                            //                     }}
                            //                   >
                            //                     Please expect the same to be updated
                            //                     within the next 48 hours.
                            //                     {/* <span style={{ fontWeight: "500" }}>
                            //   <CreateProjectModal2 />
                            // </span> */}
                            //                   </h3>
                            //                 </div>
                            //               </div>
                            //             </div>
                            //           </div>
                            //         </>
                            //       )
                          }
                        </>
                      )}

                      {educationDetails?.length > 0 ? (
                        <div className="mt-4">
                          <div className="bg-white expertBox">
                            <div className="p-4">
                              <div className="pvs-header__title-container">
                                <h2 className="pvs-header__title text-heading-large">
                                  <span aria-hidden="true">Education</span>
                                </h2>
                              </div>
                              {educationDetails?.map((curElem, id) => {
                                return (
                                  <>
                                    <div className="companyLogo mt-4">
                                      {curElem?.logo_url !== null ? (
                                        <img
                                          src={curElem?.logo_url}
                                          alt="logo"
                                          onError={addDefaultSrc}
                                        />
                                      ) : (
                                        <img src={company_icon3} alt="logo" />
                                      )}
                                      <div style={{ marginLeft: "20px" }}>
                                        <h5 className="companyName">
                                          {curElem?.institute_name}
                                        </h5>
                                        <p className="totalExperience">
                                          {curElem?.degree}
                                        </p>
                                        <p className="text-body-small inline t-black--light break-words">
                                          {curElem?.description !== null
                                            ? curElem?.description
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {expertDetails?.[0]?.project_details?.length > 0 && (
                        <div
                          className="comon-divs-re mt-3"
                          style={{ width: "100%", paddingRight: "20px" }}
                        >
                          <ProjectDetails
                            projectDetails={
                              expertDetails?.[0]?.project_details || []
                            }
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </main>

          <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body className="expertEditProfile">
              <div
                className="position-absolute closeIcons"
                onClick={() => setShow(false)}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    stroke-width="2"
                    d="M3,3 L21,21 M3,21 L21,3"
                  ></path>
                </svg>
              </div>
              <div className="text-center">
                <h5 className="">Edit Profile</h5>
              </div>
              <Form layout="vertical" autoComplete="off" form={form}>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item label="First Name">
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="First Name"
                        name="first_name"
                        value={updateValue?.first_name}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item label="Last Name">
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="Last Name"
                        name="last_name"
                        value={updateValue?.last_name}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <Form.Item label="Contact Details">
                      <PhoneInput
                        className="react-tel-input-2"
                        country={"us"}
                        enableSearch={true}
                        value={phone}
                        onChange={(value, country) =>
                          handlePhoneChange(value, country)
                        }
                        inputStyle={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Item label="Hourly Rate">
                      <Input
                        type="number"
                        addonBefore={selectBefore}
                        size="large"
                        name="hourly_rate_call_cunsltn"
                        value={updateValue?.hourly_rate_call_cunsltn}
                        onChange={hourly_rate}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Item label="Linkedin URL">
                      <Input
                        type="text"
                        autoComplete="nope"
                        size="large"
                        placeholder="https://www.linkedin.com/in/393001107/"
                        name="linkedin_url"
                        value={updateValue?.linkedin_url}
                        onChange={handleInputs}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Item name="industry" label="Main Industry">
                      {/* {Loading ? (
                        <Spin />
                      ) : ( */}
                      <Select
                        style={{
                          width: "100%",
                        }}
                        name="industry"
                        size="large"
                        mode="tags"
                        placeholder="Type for search"
                        defaultValue={
                          Array.isArray(updateValue?.industry)
                            ? updateValue.industry
                            : []
                        }
                        onChange={handleOptionIndustChange}
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                      >
                        {filteredIndustOptions?.map((option) => (
                          <Option
                            key={option?._id}
                            value={option?.category_name}
                          >
                            {option?.category_name}
                          </Option>
                        ))}
                      </Select>
                      {/* )} */}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Item name="tags" label="Tags">
                      {/* {Loading ? (
                        <Spin />
                      ) : ( */}
                      <Select
                        style={{
                          width: "100%",
                        }}
                        name="tags"
                        size="large"
                        mode="tags"
                        placeholder="Type for search"
                        defaultValue={
                          Array.isArray(updateValue?.tags)
                            ? updateValue?.tags
                            : []
                        }
                        value={selectedOptions}
                        onChange={handleOptionChange}
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        }
                      >
                        {filteredOptions?.map((option) => (
                          <Option key={option?._id} value={option?.name}>
                            {option?.name}
                          </Option>
                        ))}
                      </Select>
                      {/* )} */}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-end">
                    <a
                      className="btn btn-responsive expertButtonNext me-3"
                      onClick={() => setShow(false)}
                    >
                      Close
                    </a>
                    <button
                      className="btn btn-responsive viewButton"
                      onClick={EditProfile}
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

export default ExpertProfile;
