import React, { useState } from 'react'
import ExpertDashboard from '../../components/ExpertDashboard'
import Sidebar from '../Sidebar/Sidebar'
import { Col, Row } from 'react-bootstrap'
import ExpertProjectCard from '../../components/ExpertProjectCard'
import ScreeningQuestionModal from '../../components/ScreeningQuestionModal'
import useCTAList from '../../../pages/CloudFeatures/InternalMeetings/hooks/useCTAList'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { setIsOpenDelete, setPage, setSelectedCTAProject } from '../../../pages/TeamProjects/features/projectCTA.slice'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Pagination, Spin } from 'antd'
import {
    LoadingOutlined,
} from "@ant-design/icons";
const ExpertProjects = () => {
    const [show, setShow] = useState(false)
    const { ctaProjectList, isLoadingCtaList, handleCTAProjectDelete, applyProject, count, saveProject } = useCTAList()
    const { isOpenDelete, page, limit } = useSelector((state) => state.projectCTA);
    const dispatch = useDispatch()
    const handlePageChange = (pageNumber) => {
        dispatch(setPage(pageNumber));
    };

    const paginationSettings = {
        current: page,
        onChange: handlePageChange,
        pageSize: limit,
        total: count,
    };
    return (
        <>
            <ExpertDashboard />
            <div className="page-wrapper chiller-theme toggled">
                <div className="main-sec d-lg-flex">
                    <Sidebar />
                </div>
                <main className="body-total content-wrapper float-start w-100">
                    <div className="container-fluid">
                        <Row className="justify-content-center marginT">
                            <Col xl={11}>
                                <Row>
                                    <Col>
                                        <h5
                                            className="d-flex align-items-center mt-0"
                                            style={{ fontWeight: 600, fontSize: "26px" }}
                                        // onClick={handleIconClick}
                                        >
                                            <span
                                                className="pe-3 bckIcon"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 320 512"
                                                    height="1em"
                                                    width="1em"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path>
                                                </svg>
                                            </span>

                                            <span>Projects</span>
                                        </h5>
                                    </Col>
                                </Row>

                                {isLoadingCtaList ? <div className='d-flex justify-content-center align-items-center'>
                                    <Spin
                                        size="large"
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    paddingRight: 5,
                                                }}
                                            />
                                        }
                                    />
                                </div> : <div>
                                    {ctaProjectList?.map((hit, index) => (
                                        <div key={index}>
                                            <ExpertProjectCard hit={hit} index={index} show={show} setShow={setShow} saveProject={saveProject} />
                                        </div>
                                    ))}
                                    <Pagination
                                        {...paginationSettings}
                                        showSizeChanger={false}
                                        style={{
                                            marginTop: 16,
                                            marginBottom: 16,
                                            textAlign: "right",
                                        }}
                                    />
                                </div>}
                            </Col>
                        </Row>
                    </div>
                </main>
            </div>
            <ScreeningQuestionModal show={show} setShow={setShow} applyProject={applyProject} />
            <ConfirmationModal
                open={isOpenDelete}
                setOpen={() => {
                    dispatch(setIsOpenDelete(false))
                    dispatch(setSelectedCTAProject({}))
                }}
                message={"Are you sure you want to delete this project?"}
                title={`Delete`}
                okFunction={async () => {
                    handleCTAProjectDelete()
                }}
                cancelFunction={() => {
                    dispatch(setIsOpenDelete(false))
                    dispatch(setSelectedCTAProject({}))
                }}
            />
        </>
    )
}

export default ExpertProjects
