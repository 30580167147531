import { Select } from "antd";
import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import API_PATH from "../../Constants/api-path";
import "../../assests/css/custom.css";
import "../CallRecords/CallRecords.css";
import { toQueryString } from "../../Utils/util";
import { Col, Row } from "react-bootstrap";
const SelectionOption = ({
  endpoint = "expert_search",
  expert_type = "",
  stateList,
  stateFunctionList,
  stateListFilter,
  stateFunctionListFilter,
  callOnce,
  callFilter,
  handleSearchExperts,
  getExpertsOptions,
  filterRef,
}) => {
  const {
    allEmail,
    allGeog,
    allRegion,
    allIndustry,
    allCompany,
    allPreviousCompany,
    allDesig,
    allExperties,
  } = stateListFilter;

  const {
    current_company,
    previous_company,
    email,
    industry,
    country,
    regions,
    designation,
    experties,
  } = stateList;
  const {
    setCompany,
    setPreviousCompany,
    setEmail,
    setIndustry,
    setCountry,
    setRegion,
    setDesignation,
    setExperties,
    setWordEntered,
    setSearchRgx,
    setCurrentPage
  } = stateFunctionList;
  const {
    setAllEmail,
    setAllGeog,
    setAllRegion,
    setAllIndustry,
    setAllCompany,
    setAllPreviousCompany,
    setAllDesig,
    setAllExperties,
  } = stateFunctionListFilter;
  const catMenu = useRef(null);
  const [, setopenSlide] = useState(false);
  // const [allEmail, setAllEmail] = useState([]);
  // const [allGeog, setAllGeog] = useState([]);
  // const [allIndustry, setAllIndustry] = useState([]);
  // const [allCompany, setAllCompany] = useState([]);
  // const [allPreviousCompany, setAllPreviousCompany] = useState([]);
  // const [allDesig, setAllDesig] = useState([]);
  // const [experties, setAllExperties] = useState([]);
  let history = useNavigate();

  // const [companyOption, setFilteredCompany] = useState("");
  // const [previousCompanyOption, setFilteredCompanyPrevious] = useState("");
  // const [emailOption, setFilteredEmail] = useState("");
  // const [industriesOption, setFilteredIndustries] = useState("");
  // const [geogOption, setFilteredGeog] = useState("");
  // const [desigOption, setFilteredDesig] = useState("");
  // const [expertiesOption, setFilteredExperties] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));

  // const getExpertsOptions = React.useCallback(() => {
  //   const queryParamater = {
  //     email: email ? email : "",
  //     industry: industry ? industry : "",
  //     country: country ? country : "",
  //     designation: designation ? designation : "",
  //     tags: experties ? experties : "",
  //     current_company: current_company ? current_company : "",
  //     previous_company: previous_company ? previous_company : "",
  //     key: searchRgx ? searchRgx : "",
  //     expert_type: expert_type === "consultant" ? expert_type : "",
  //   };
  //   // const queryParamater = {
  //   //   email: emailOption ? emailOption : email ? email : "",
  //   //   industry: industriesOption ? industriesOption : industry ? industry : "",
  //   //   country: geogOption ? geogOption : country ? country : "",
  //   //   designation: desigOption ? desigOption : designation ? designation : "",
  //   //   tags: expertiesOption ? expertiesOption : experties ? experties : "",
  //   //   current_company: companyOption ? companyOption : current_company ? current_company : "",
  //   //   previous_company: previousCompanyOption ? previousCompanyOption : previous_company ? previous_company : "",
  //   //   key: searchRgx ? searchRgx : "",
  //   //   expert_type: expert_type === "consultant" ? expert_type : "",
  //   // };
  //   setLoading(true);
  //   // let new_params = new URLSearchParams([
  //   //   ...Object.entries({ ...queryParamater }),
  //   // ]);
  //   let paramsforQuery = new URLSearchParams([...Object.entries({
  //     ...toQueryString({ ...queryParamater })
  //   })])
  //   axios
  //     // .get(`${API_PATH.EXPERT_OPTION}?${new_params.toString()}`, {
  //     .get(`${API_PATH.EXPERT_OPTION}?${paramsforQuery.toString()}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${userData?.token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setAllCompany(filterDuplicates(res?.data?.company));
  //       setAllPreviousCompany(filterDuplicates(res?.data?.previousCompany));
  //       setAllEmail(filterDuplicates(res?.data?.email));
  //       setAllGeog(filterDuplicates(res?.data?.country));
  //       setAllIndustry(filterDuplicates(res?.data?.industry));
  //       setAllDesig(filterDuplicates(res?.data?.designationNew));
  //       setAllExperties(filterDuplicates(res?.data?.tags));
  //       setLoading(false); // Move setLoading to false here to trigger a re-render after data is fetched
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoading(false);
  //     });
  //   setLoading(false);
  // }, [current_company, designation, email, expert_type, experties, country, industry, previous_company, searchRgx, setAllCompany, setAllDesig, setAllEmail, setAllExperties, setAllGeog, setAllIndustry, setAllPreviousCompany, userData?.token]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (catMenu?.current && !catMenu?.current?.contains(event.target)) {
        setopenSlide(true);
      }
    }
    let handler;
    handler = setTimeout(async () => {
      if (!callOnce.current && !callFilter.current) {
        callOnce.current = true;
        callFilter.current = true;
        getExpertsOptions();
      }
      document.addEventListener("click", handleOutsideClick);
      setSelectedValues(current_company);
      setSelectedValuesPrevious(previous_company);
      setSelectedEmail(email);
      setSelectedIndustry(industry);
      setSelectedGeog(country);
      setSelectedRegion(regions)
      setSelectedDesig(designation);
      setSelectedExperties(experties);
    }, 1000);

    return () => {
      document?.removeEventListener("click", handleOutsideClick);
      clearTimeout(handler);
    };
  }, [callFilter, callOnce, current_company, designation, email, experties, country, getExpertsOptions, industry, previous_company, regions]);

  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesPrevious, setSelectedValuesPrevious] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedGeog, setSelectedGeog] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedDesig, setSelectedDesig] = useState([]);
  const [selectedExperties, setSelectedExperties] = useState([]);

  let queryParams = queryString.parse(window.location.search);

  const Replacepagechange = React.useCallback(
    (
      searchRgx,
      current_company,
      previous_company,
      email,
      industry,
      country,
      regions,
      designation,
      experties,
      page
    ) => {
      history({
        pathname: `/dashboard/${endpoint}`,
        search: `?key=${searchRgx}&current_company=${current_company}&previous_company=${previous_company}&email=${email}&industry=${industry}&country=${country}&regions=${regions}&designation=${designation}&tags=${experties}&page=${page}`,
      });
    },
    [endpoint, history]
  );

  const handleChange = (value) => {
    setCompany(value);
    setSelectedValues(value);
    Replacepagechange(
      queryParams.key || "",
      JSON.stringify(value),
      queryParams.previous_company || "",
      queryParams.email || "",
      queryParams.industry || "",
      queryParams.country || "",
      queryParams.regions || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };
  const handleChangePrevious = (value) => {
    setPreviousCompany(value);
    setSelectedValuesPrevious(value);

    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      JSON.stringify(value),
      queryParams.email || "",
      queryParams.industry || "",
      queryParams.country || "",
      queryParams.regions || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };

  const handleChangeEmail = (value) => {
    setSelectedEmail(value);
    setEmail(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      JSON.stringify(value) || "",
      queryParams.industry || "",
      queryParams.country || "",
      queryParams.regions || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };

  const handleChangeIndustry = (value) => {
    setSelectedIndustry(value);
    setIndustry(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.email || "",
      JSON.stringify(value) || "",
      queryParams.country || "",
      queryParams.regions || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };

  const handleChangeGeog = (value) => {
    setSelectedGeog(value);
    setCountry(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.email || "",
      queryParams.industry || "",
      JSON.stringify(value),
      queryParams.regions || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };
  const handleChangeRegion = (value) => {
    setSelectedRegion(value);
    setRegion(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.email || "",
      queryParams.industry || "",
      queryParams.country || "",
      JSON.stringify(value),
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };

  const handleChangedesig = (value) => {
    setSelectedDesig(value);
    setDesignation(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.email || "",
      queryParams.industry || "",
      queryParams.country || "",
      JSON.stringify(value),
      queryParams.tags || "",
      1
    );
  };
  const handleChangeExperties = (value) => {
    setSelectedExperties(value);
    setExperties(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.email || "",
      queryParams.industry || "",
      queryParams.country || "",
      queryParams.designation || "",
      JSON.stringify(value),
      1
    );
  };

  const handleClearAll = React.useCallback(() => {
    filterRef.current.search = false;
    filterRef.current.count = 0;
    callOnce.current = false;
    callFilter.current = false;

    localStorage.removeItem("filterStates");
    setCurrentPage(1)
    setWordEntered("");
    setSearchRgx("");
    setCompany("");
    setPreviousCompany("");
    setEmail("");
    setIndustry("");
    setCountry("");
    setRegion("");
    setDesignation("");
    setExperties("");
    setSelectedValues([]);
    setSelectedValuesPrevious([]);
    setSelectedEmail([]);
    setSelectedIndustry([]);
    setSelectedGeog([]);
    setSelectedRegion([])
    setSelectedDesig([]);
    setSelectedExperties([]);
    // queryParams?.key || "",
    Replacepagechange("", "", "", "", "", "", "", "", 1);
    handleSearchExperts(true);
  }, [filterRef, callOnce, callFilter, setCurrentPage, setWordEntered, setSearchRgx, setCompany, setPreviousCompany, setEmail, setIndustry, setCountry, setRegion, setDesignation, setExperties, Replacepagechange, handleSearchExperts]);

  const expertFilterSearch = React.useCallback(
    async (key, value) => {
      const queryParamater = {
        // email: email ? email : "",
        // industry: industry ? industry : "",
        // country: country ? country : "",
        // designation: designation ? designation : "",
        // tags: experties ? experties : "",
        // current_company: current_company ? current_company : "",
        // previous_company: previous_company ? previous_company : "",
        // key: searchRgx ? searchRgx : "",
        search: value ? value : "",
        searchkey: key ? key : "",
        expert_type: expert_type === "consultant" ? "consultant" : "expert",
      };
      // setLoading(true);
      // let new_params = new URLSearchParams([
      //   ...Object.entries({ ...queryParamater }),
      // ]);

      let paramsforQuery = new URLSearchParams([
        ...Object.entries({
          ...toQueryString({ ...queryParamater }),
        }),
      ]);
      await axios
        .get(`${API_PATH.EXPERT_FILTER_SEARCH}?${paramsforQuery.toString()}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        })
        .then((res) => {
          if (key === "current_company") {
            setAllCompany(
              filterDuplicates([...allCompany, ...res?.data?.data])
            );
          } else if (key === "email") {
            setAllEmail(filterDuplicates([...allEmail, ...res?.data?.data]));
          } else if (key === "industry") {
            setAllIndustry(
              filterDuplicates([...allIndustry, ...res?.data?.data])
            );
          } else if (key === "country") {
            setAllGeog(filterDuplicates([...allGeog, ...res?.data?.data]));
          } else if (key === "regions") {
            setAllRegion(filterDuplicates([...allRegion, ...res?.data?.data]));

          } else if (key === "designation") {
            setAllDesig(filterDuplicates([...allDesig, ...res?.data?.data]));
          } else if (key === "tags") {
            setAllExperties(
              filterDuplicates([...allExperties, ...res?.data?.data])
            );
          } else if (key === "previous_company") {
            setAllPreviousCompany(
              filterDuplicates([...allPreviousCompany, ...res?.data?.data])
            );
          }

          // setLoading(false);
        })
        .catch((error) => {
          console.error("error: ", error);
          // setLoading(false);
        });
    },
    [allCompany, allDesig, allEmail, allExperties, allGeog, allIndustry, allPreviousCompany, allRegion, expert_type, setAllCompany, setAllDesig, setAllEmail, setAllExperties, setAllGeog, setAllIndustry, setAllPreviousCompany, setAllRegion, userData?.token]
  );

  const [filterStates, setFilterStates] = useState({
    name: "",
    value: "",
  });
  useEffect(() => {
    let states = JSON.parse(localStorage.getItem("filterStates"));
    if (states && states.path === "/expert-profile") {
      callOnce.current = true;
      callFilter.current = true;
      // setFilterStates(states)
      localStorage.removeItem("filterStates");
    }
    localStorage.removeItem("filterStates");
  }, [callFilter, callOnce]);

  const handleSearch = React.useCallback((value, name) => {
    setFilterStates({
      name: name,
      value: value,
    });
    localStorage.setItem(
      "filterStates",
      JSON.stringify({
        name: name,
        value: value,
      })
    );
  }, []);

  const callSearchOnce = useRef(false);
  useEffect(() => {
    // ----------NEW COMMENTED-------------------
    let handler;
    if (
      filterStates.name !== "" &&
      filterStates.value !== "" &&
      !callSearchOnce.current
    ) {
      handler = setTimeout(async () => {
        callSearchOnce.current = true;
        expertFilterSearch(filterStates.name, filterStates.value);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
    // ----------NEW COMMENTED-------------------
    // ----------OLD COMMENTED-------------------
    //  else {
    //   callOnce.current = true
    // }
    // else {
    //   if (callOnce.current) {
    //     handler = setTimeout(async () => {
    //       getExperts(queryParams.page);
    //     }, 1000);
    //   }
    // }
    // ----------OLD COMMENTED-------------------
    // ----------NEW COMMENTED-------------------
  }, [expertFilterSearch, filterStates.name, filterStates.value]);
  // ----------NEW COMMENTED-------------------
  return (
    <div className="select-option customRes" ref={catMenu}>
      {/* <div className="row row-cols-lg-2 row-cols-xl-5 g-2"> */}
      <Row className="g-2">
        <Col className="mb-2">
          <div className="location">
            <Select
              showSearch
              placeholder="Select Company"
              className="card-dropdown"
              mode="multiple"
              size="large"
              optionFilterProp="children"
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "current_company");
              }}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }

              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              onChange={handleChange}
              // mode="multiple"
              value={
                Array.isArray(selectedValues)
                  ? selectedValues
                  : selectedValues
                    ? [selectedValues]
                    : []
              }
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
            >
              {allCompany
                ?.filter((item) => !selectedValues?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Previous Company"
              className="card-dropdown"
              size="large"
              optionFilterProp="children"
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "previous_company");
              }}
              onChange={handleChangePrevious}
              // mode="multiple"
              value={
                Array.isArray(selectedValuesPrevious)
                  ? selectedValuesPrevious
                  : selectedValuesPrevious
                    ? [selectedValuesPrevious]
                    : []
              }
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
            >
              {allPreviousCompany
                ?.filter((item) => !selectedValuesPrevious?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>

        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Email"
              className="card-dropdown"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeEmail}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "email");
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              // mode="multiple"
              value={
                Array.isArray(selectedEmail)
                  ? selectedEmail
                  : selectedEmail
                    ? [selectedEmail]
                    : []
              }
            >
              {allEmail
                ?.filter((item) => !selectedEmail?.includes(item?._id))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option
                        key={item?._id || item}
                        value={item?._id || item}
                      >
                        {item?._id || item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
      </Row>

      <Row className="g-2">
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Industries"
              className="card-dropdown"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeIndustry}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "industry");
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) => {
                const optionText = option?.props?.children;
                if (typeof optionText === "string") {
                  return (
                    optionText?.toLowerCase()?.indexOf(input?.toLowerCase()) !==
                    -1
                  );
                }
                return false;
              }}
              // mode="multiple"
              value={
                Array.isArray(selectedIndustry)
                  ? selectedIndustry
                  : selectedIndustry
                    ? [selectedIndustry]
                    : []
              }
            >
              {allIndustry
                ?.filter((item) => !selectedIndustry?.includes(item))
                ?.map((item, index) => (
                  <Select.Option key={item} value={item}>
                    {item?.charAt(0)?.toUpperCase() +
                      item?.slice(1)?.toLowerCase()}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Country"
              className="card-dropdown"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeGeog}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "country");
              }}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              value={
                Array.isArray(selectedGeog)
                  ? selectedGeog.map((item) => {
                    return (
                      item?.charAt(0)?.toUpperCase() +
                      item?.slice(1)?.toLowerCase()
                    );
                  })
                  : selectedGeog
                    ? [
                      selectedGeog?.charAt(0)?.toUpperCase() +
                      selectedGeog?.slice(1)?.toLowerCase(),
                    ]
                    : []
              }
            >
              {allGeog
                ?.filter((item) => !selectedGeog?.includes(item))
                ?.map((item, index) => {
                  const capitalizedItem =
                    item?.charAt(0)?.toUpperCase() +
                    item?.slice(1)?.toLowerCase();

                  return (
                    <Select.Option key={item} value={item}>
                      {capitalizedItem}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Region"
              className="card-dropdown"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeRegion}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "regions");
              }}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              value={
                Array.isArray(selectedRegion)
                  ? selectedRegion.map((item) => {
                    return (
                      item?.charAt(0)?.toUpperCase() +
                      item?.slice(1)?.toLowerCase()
                    );
                  })
                  : selectedRegion
                    ? [
                      selectedRegion?.charAt(0)?.toUpperCase() +
                      selectedRegion?.slice(1)?.toLowerCase(),
                    ]
                    : []
              }
            >
              {allRegion
                ?.filter((item) => !selectedRegion?.includes(item))
                ?.map((item, index) => {
                  const capitalizedItem =
                    item?.charAt(0)?.toUpperCase() +
                    item?.slice(1)?.toLowerCase();

                  return (
                    <Select.Option key={item} value={item}>
                      {capitalizedItem}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Designation"
              className="card-dropdown"
              size="large"
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              optionFilterProp="children"
              onChange={handleChangedesig}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "designation");
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              // mode="multiple"
              value={
                Array?.isArray(selectedDesig)
                  ? selectedDesig
                  : selectedDesig
                    ? [selectedDesig]
                    : []
              }
            >
              {allDesig
                ?.filter((item) => !selectedDesig?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Expertise"
              className="card-dropdown"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeExperties}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll()
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1
                  }
                }
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "tags");
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              // mode="multiple"
              value={
                Array?.isArray(selectedExperties)
                  ? selectedExperties
                  : selectedExperties
                    ? [selectedExperties]
                    : []
              }
            >
              {allExperties
                ?.filter((item) => !selectedExperties?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
      </Row>

      <div className="mt-2">
        {/* {selectedValuesPrevious != null && selectedValuesPrevious.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            {selectedValuesPrevious?.map((selectedItems, id) => (
              <div key={id} className="mb-2">
                <Chip
                  className="me-1 locationSpeed"
                  label={selectedItems ? selectedItems : null}
                  onDelete={() => handleDeletePreviousCompany(id)}
                />
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
        {selectedValues != null && selectedValues.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            {selectedValues?.map((selectedItems, id) => (
              <div key={id} className="mb-2">
                <Chip
                  className="me-1 locationSpeed"
                  label={selectedItems ? selectedItems : null}
                  onDelete={() => handleDelete(id)}
                />
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}

        {selectedEmail != null && selectedEmail.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            <>
              {selectedEmail?.map((selectedItems, id) => (
                <div key={id} className="mb-2">
                  <Chip
                    className="me-1 locationSpeed"
                    key={id}
                    label={selectedItems ? selectedItems : null}
                    onDelete={() => handleDeleteEmail(id)}
                  />
                </div>
              ))}
            </>
          </div>
        ) : (
          <></>
        )} */}

        {/* {selectedIndustry != null && selectedIndustry.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            <>
              {selectedIndustry?.map((selectedItems, id) => (
                <div key={id} className="mb-2">
                  <Chip
                    className="me-1 locationSpeed"
                    key={id}
                    label={selectedItems ? selectedItems : null}
                    onDelete={() => handleDeleteIndust(id)}
                  />
                </div>
              ))}
            </>
          </div>
        ) : (
          <></>
        )}

        {selectedGeog != null && selectedGeog?.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            <>
              {selectedGeog?.map((selectedItems, id) => {
                const capitalizedItem =
                  selectedItems?.charAt(0)?.toUpperCase() +
                  selectedItems?.slice(1)?.toLowerCase();

                return (
                  <div key={id} className="mb-2">
                    <Chip
                      className="me-1 locationSpeed"
                      key={id}
                      label={capitalizedItem ? capitalizedItem : null}
                      onDelete={() => handleDeleteGeog(id)}
                    />
                  </div>
                );
              })}
            </>
          </div>
        ) : (
          <></>
        )} */}

        {/* {selectedDesig != null && selectedDesig.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            <>
              {selectedDesig?.map((selectedItems, id) => (
                <div key={id} className="mb-2">
                  <Chip
                    className="me-1 locationSpeed"
                    key={id}
                    label={selectedItems ? selectedItems : null}
                    onDelete={() => handleDeleteDesig(id)}
                  />
                </div>
              ))}
            </>
          </div>
        ) : (
          <></>
        )}
        {selectedExperties != null && selectedExperties.length > 0 ? (
          <div className="d-flex flex-wrap align-content-around align-items-center me-2 mt-1">
            <>
              {selectedExperties?.map((selectedItems, id) => (
                <div key={id} className="mb-2">
                  <Chip
                    className="me-1 locationSpeed"
                    key={id}
                    label={selectedItems ? selectedItems : null}
                    onDelete={() => handleDeleteExperties(id)}
                  />
                </div>

              ))}
            </>
          </div>
        ) : (
          <></>
        )} */}

        {filterRef.current.count > 0 && (
          <button
            className="btn viewButton"
            style={{ padding: "5px", fontSize: "14px" }}
            onClick={() => handleSearchExperts()}
          >
            Search
          </button>
        )}

        {filterRef.current.count > 0 && <button className="btn viewButton m-2" style={{ padding: "5px", fontSize: "14px" }}
          onClick={handleClearAll}>Reset Filter</button>}

      </div>
    </div>
  );
};

export default SelectionOption;
